import { useState } from "react";
import dealPoster from "../../../assets/images/deal-poster.png";

function Overview({ overview, className }) {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      <div
        className={`booking-page-content booking-page-detail-content ${
          className ? className : ""
        }`}
      >
        <div className="review-section">
          <h2 className="review-title">
            <span>{`${overview?.title}`} </span> <>Overview</>{" "}
          </h2>
          <div className="review-text">
            <p
              className="d-inline"
              dangerouslySetInnerHTML={{
                __html: isReadMore
                  ? overview?.description.slice(0, 150).replace(/\n/g, "<br>")
                  : overview?.description.replace(/\n/g, "<br>"),
              }}
            />
            <span
              style={{ cursor: "pointer" }}
              className="read-more-text"
              onClick={() => toggleReadMore()}
            >
              {isReadMore ? "...Read more" : " Read less"}
            </span>
          </div>
          <div className="offer-deal">
            <div className="offer-deal-icon">
              <img src={dealPoster} alt="" />
            </div>
            <div className="offer-deal-text">
              <p>
                <em>{overview?.offer_description}</em>
              </p>
            </div>
          </div>
          {/* <div className="offer-wrap">
                        {indicators?.length > 0 ? (
                          <>
                            <h2 className="review-title">What this Offers</h2>
                            {indicators.map((item, index) => {
                              return (
                                <ul className="what-offers">
                                  <li>{item.text}</li>
                                </ul>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </div> */}
        </div>
      </div>
    </>
  );
}
export default Overview;
