import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MissingInformation({ ...data }) {
  return (
    <>
      <div
        className={`booking-page-content booking-page-detail-content ${data?.className}`}
        id="reviewSection"
      >
        <div
          id="yourSpecificDivId"
          className="review-section d-flex align-items-start justify-content-between"
        >
          <button
            type="button"
            className="btn  review-modal-btn btn-primary justify-content-between d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#missingInfo"
          >
            <div className="let-text-wrap text-start">
              <h2 className="review-title mx-0 mb-0">
                Missing Information of <span>{data.activityData?.title} </span>
              </h2>
              <span className="text-muted text-start">Let us know</span>
            </div>
            <span
              className="fa-solid fa-chevron-right"
              aria-hidden="true"
            ></span>
          </button>
          <FontAwesomeIcon
            icon="fa-solid fa-chevron-right"
            style={{ marginTop: "10px" }}
          />
        </div>
      </div>
    </>
  );
}
export default MissingInformation;
