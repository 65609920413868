import API from "../../../services/ApiService";
import { ApiEndPoint } from "../../../services/ApiEndPoint";
import { useEffect, useState } from "react";

function Attractions({ ...data }) {
  var countryCode = localStorage.getItem("country_code");
  var country_code = countryCode ? countryCode : "ae";
  const [attractions, setAttractions] = useState([]);

  const getAttractions = () => {
    API.get(
      ApiEndPoint.recommendedApi +
        data.activityId +
        "/related-attractions/?country_code=" +
        country_code +
        "",
      data.config
    )
      .then((response) => {
        console.log(response.data);
        setAttractions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAttractions();
  }, []);
  return (
    <>
      {attractions.length > 0 ? (
        <div className="booking-page-content booking-page-detail-content">
          <h2 className="review-title">More Attractions</h2>
          <div className="attraction-content">
            {attractions.map((item) => (
              <>
                <a href={`/${item.url}`} className="attraction-link">
                  {item.title}
                </a>
              </>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}
export default Attractions;
