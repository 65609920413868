import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './footer.css';
import { NavLink } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import footerLogo from '../../assets/images/logo.png';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LazyLoadImage } from 'react-lazy-load-image-component';


function Footer() {
  const { t } = useTranslation();

  return (
    <footer className='footer main-footer'>
      <Container>
        <Row>
          <Col md={6} lg={3} xl={5}>
            <div className="kidzapp-content">
              <LazyLoadImage width="154px" height="33px" alt="website logo" src={footerLogo} loading="lazy" />
              <p className="kidz-text">{t('get_inspired_des')}</p>
              <div className="store-logo">
                <NavLink className="nav-link" to="https://apps.apple.com/us/app/kidzapp-dubai-family-guide/id1255769149" target="_blank">
                  <LazyLoadImage width="142px" height="40px" alt="footer logo" src={"https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/image522.png"} loading="lazy" />
                </NavLink>
                <NavLink className="nav-link" to="https://play.google.com/store/apps/details?id=com.kidzapp" target="_blank">
                  <LazyLoadImage width="134px" height="40px" alt="footer logo" src={"https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/image523.png"} loading="lazy" />
                </NavLink>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3}>
            <div className="widget-text">
              <div className="widget-title">{t('kids_Act_by_cat')} {t('kids_Act_by_cat_split')}</div>
              <ul>
                <li><a className="nav-link" href="/find-activities/?category=37">{t('animalFun')}</a></li>
                <li><a className="nav-link" href="/find-activities/?category=66512">{t('artMusicDance')}</a></li>
                <li><a className="nav-link" href="find-activities/?category=66513">{t('babyToddler')}</a></li>
                <li><a className="nav-link" href="/find-activities/?category=51">{t('birthdays')}</a></li>
                <li><a className="nav-link" href="/find-activities/?category=66515">{t('courseCamps')}</a></li>

              </ul>
            </div>
          </Col>
          <Col md={6} lg={2}>
            <div className="widget-text">
              <div className="widget-title">{t('family_activity')}</div>
              <ul>
                <li><a className="nav-link" href="/find-activities/?type=venue">{t('venues')}</a></li>
                <li><a className="nav-link" href="/find-activities/?type=course">{t('courses')}</a></li>
                <li><a className="nav-link" href="/find-activities/?type=event">{t('events')}</a></li>
              </ul>
            </div>
            <div className="widget-text">
              <div className="widget-title">{t('indoor_outdoor_kids_act')} {t('indoor_outdoor_kids_act_split')}</div>
              <ul>
                <li><a className="nav-link" href="/find-activities/?space=indoors">{t('indoor')}</a></li>
                <li><a className="nav-link" href="/find-activities/?space=outdoors">{t('outdoor')}</a></li>
              </ul>
            </div>
          </Col>
          <Col md={6} lg={2}>
            <div className="widget-text">
              <div className="widget-title">{t('others')}</div>
              <ul>
                <li><a className="nav-link" href="/find-activities">{t('find_act')}</a></li>
                <li><a className="nav-link" href="/our-story">{t('our_story')}</a></li>
                <li><a className="nav-link" href="/how-the-app-works">{t('about_the_app')}</a></li>
                <li><a className="nav-link" href="/get-in-touch">{t('get_in_touch')}</a></li>
                <li><a className="nav-link" href="/blogs">{t('blog')}</a></li>
                <li><a className="nav-link" href="/sitemap">{t('site_map')}</a></li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="copyright-content">
        <Container>
          <Row>
            <Col md={12} lg={4}>
              <div className="copyright-text">
                <p><NavLink className="nav-link" to="">{t('copyRight')}</NavLink></p>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="widget-text">
                <ul className="d-flex align-itens-center justify-content-center">
                  <li><NavLink className="nav-link" to="privacy-policy">{t('privacy_policy')}</NavLink></li>
                  <li><NavLink className="nav-link" to="terms-and-conditions">{t('terms_of_use')}</NavLink></li>
                  <li><NavLink className="nav-link" to="cancellation-policy">{t('cancellation_policy')}</NavLink></li>
                </ul>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <ul className="social-links-top d-flex justify-content-center justify-content-md-end align-items-center">
                <li className="link-item">
                  <NavLink className="nav-link" aria-label="Read more about kidzapp facebook page" to="https://www.facebook.com/kidzappAE/" target="_blank">
                    <FontAwesomeIcon icon="fa-brands fa-facebook" />

                  </NavLink>
                </li>
                <li className="link-item">
                  <NavLink className="nav-link" aria-label="Read more about kidzapp twitter page" to="https://twitter.com/i/flow/login?redirect_after_login=%2Fkidzapp2%2F" target="_blank">
                    <FontAwesomeIcon icon="fa-brands fa-twitter-square" />
                  </NavLink>
                </li>
                <li className="link-item">
                  <NavLink className="nav-link" aria-label="Read more about kidzapp whatsapp" to="https://api.whatsapp.com/send?phone=201204133928&text=Hi%2C+I%27m+browsing+home+screen+and+have+some+questions" target="_blank">
                    <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                  </NavLink>
                </li>
                <li className="link-item">
                  <NavLink className="nav-link" aria-label="Read more about kidzapp instagram account" to="https://www.instagram.com/kidzappuae/" target="_blank">
                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                  </NavLink>
                </li>
                <li className="link-item">
                  <NavLink className="nav-link" aria-label="Read more about kidzapp youtube channel" to="https://www.youtube.com/channel/UCcWhXfXzQ8cbVvJC3cGgu2Q" target="_blank">
                    <FontAwesomeIcon icon="fa-brands fa-youtube" />
                  </NavLink>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;