import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bannerSearchButton from "../../../assets/images/banner-search-icon.png";
import kidzWithBaloon from "../../../assets/images/kidzwithbaloon.png";
import kidzWithSun from "../../../assets/images/kidzwithsun.png";
import "./banner.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
function Banner() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  var countryCode = localStorage.getItem("country_code");
  var country_code = countryCode ? countryCode : "ae";
  const [searchType, setSearchType] = useState("search_by");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchError, setSearchError] = useState("");
  const goforsearch = () => {
    if (searchKeyword.length > 0) {
      if (searchType === "blog") {
        navigate("/blogs/?search=" + searchKeyword);
        setSearchError("");
      } else {
        navigate("/find-activities/?search=" + searchKeyword);
        setSearchError("");
      }
    } else {
      setSearchError("Please Enter Keyword to Search");
    }
  };

  return (
    <div className="wrapper-home">
      <section className="section banner-section">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="banner-text">
                <img
                  className="left-banner-image"
                  alt="banner"
                  src={kidzWithBaloon}
                />
                <img
                  className="right-banner-image"
                  alt="banner"
                  src={kidzWithSun}
                />

                <h1 className="banner-title">
                  {t("find_best_place_egp")}{" "}
                  {country_code === "ae" ? (
                    <span>{t("find_best_place_uae_color")}</span>
                  ) : country_code === "eg" ? (
                    <span>{t("find_best_place_egp_color")}</span>
                  ) : (
                    <span>{t("find_best_place_ca_color")}</span>
                  )}{" "}
                </h1>
                <p>
                  {t("our_picks")}{" "}
                  {country_code === "ae" ? (
                    <span>{t("uae_picks")}</span>
                  ) : country_code === "eg" ? (
                    <span>{t("eg_picks")}</span>
                  ) : (
                    <span>{t("ca_picks")}</span>
                  )}{" "}
                </p>
                <div className="banner-search-form d-flex justify-content-center">
                  <div className="form-group d-flex justify-content-center align-items-center">
                    <div className="dropdown">
                      <select
                        area-aria-labelledby="searchby"
                        className="form-select btn btn-primary dropdown-toggle"
                        onChange={(e) => {
                          setSearchType(e.target.value);
                        }}
                      >
                        <option value={"search_by"}>{t("search_by")}</option>
                        <option value={"exp"}>{t("exp")}</option>
                        <option value={"blog"}>{t("blog")}</option>
                      </select>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("search")}
                      value={searchKeyword}
                      onChange={(e) => {
                        setSearchKeyword(e.target.value);
                        setSearchError("");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          goforsearch();
                        }
                      }}
                    />
                    <Button
                      className="search-btn"
                      onClick={() => {
                        goforsearch();
                      }}
                    >
                      <LazyLoadImage
                        width={56}
                        height={57}
                        src={bannerSearchButton}
                        alt="Search button"
                      />
                    </Button>
                  </div>
                </div>
                <span className="error" style={{ color: "red" }}>
                  {searchError}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
export default Banner;
