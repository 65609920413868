import "./App.css";
import React, {
  useLayoutEffect,
  useEffect,
  useState,
  Suspense,
} from "react";
import { Routes, useLocation, Route } from "react-router-dom";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Footer from "./components/footer/footer";
import "./assets/css/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



// import Home from "./pages/home/home";
// import Centers from "./pages/centers/centers";
// import MyAccount from "./pages/myAccount";
// import AboutUs from "./pages/ourStory";
// import GetInTouch from "./pages/getInTouch";
// import Boookings from "./pages/boookings";
// import BookingReview from "./pages/booking-review";
// import PaymentMethod from "./pages/payment-method";
import Details from "./pages/details";
// import PaymentMethodFatoorah from "./components/payment/paymentMethod";
// import FindActivities from "./pages/activities";
// import ViewBooking from "./pages/view-booking";
// import Blogs from "./pages/blogs";
// import BlogDetails from "./pages/blog-details";
// import Awards from "./pages/awards";
// import AwardsVote from "./pages/awards-vote";
// import PrivacyPolicy from "./pages/privacy-policy";
// import VoteSuccess from "./pages/vote-success";
// import KidzAppTv from "./pages/kidzapp-tv";
// import KidzAppTvDetail from "./pages/kidzapp-tv-detail";
// import KidzAppTvSearch from "./pages/kidzapp-tv-search";
// import TermsAndConditions from "./pages/terms-and-cnditions";
// import CancellationPolicy from "./pages/cancellation-policy";
// import Mybookings from "./pages/mybookings";
// import MyWishList from "./pages/my-wishlist";
// import CategoriesProfile from "./pages/categories";
// import Wallet from "./pages/wallet";
// import AllhandpickedExperience from "./components/_home/HandpickedExperience/allhandpickedExperience";
// import CuratedList from "./pages/curated-list";
// import AppWorks from "./pages/app-works";
// import SiteMap from "./pages/site-map";
// import AdyenPayment from "./components/adhyenPayment/adhyenPayment";

import MetaTags from "./components/metaTags/metaTags";
import tEn from "./locales/en/transaltion.json";
import tAr from "./locales/ar/transaltion.json";
import { AboutUs, AllhandpickedExperience, AppWorks, Awards, AwardsVote, BlogDetails, Blogs, BookingReview, Boookings, CancellationPolicy, CategoriesProfile, Centers, CuratedList, FindActivities, GetInTouch, KidzAppTv, KidzAppTvDetail, KidzAppTvSearch, MyAccount, MyWishList, Mybookings, PaymentMethod, PaymentMethodFatoorah, PrivacyPolicy, SiteMap, TermsAndConditions, ViewBooking, VoteSuccess, Wallet } from "./lazy";
import Header from "./components/header/header";
import Loader from "./components/_home/Loader/loader";
import Home from "./pages/home/home";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: tEn,
      },
      ar: {
        translation: tAr,
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  // console.log = console.warn = console.error = () => {};
  const location = useLocation();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("English");
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

 
  useEffect(() => {
    let lang = localStorage.getItem("lang");

    if (lang == null) {
      setLanguage("English");
    } else if (lang == "en") {
      setLanguage("English");
    } else if (lang == "ar") {
      setLanguage("Arabic");
    }
  }, []);

  useEffect(() => {
  
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

 
  return (
    <div dir={language === "English" ? "ltr" : "rtl"} className="App">
      <MetaTags />
      <div className="wrapper">
        <Header />
        <Suspense fallback={<Loader />}>

          <Routes>
          <Route strict path="/" element={<Home />} />
          <Route path="centers" element={<Centers />} />
          <Route path="myaccount" element={<MyAccount />} />
          <Route path="our-story" element={<AboutUs />} />
          <Route path="get-in-touch" element={<GetInTouch />} />
          <Route path="bookings/:id" element={<Boookings />} />
          <Route path="view-booking/:id" element={<ViewBooking />} />
          <Route path="booking-review" element={<BookingReview />} />
          <Route path="payment-method" element={<PaymentMethod />} />

            <Route
              path="payment-method-myfatoorah"
              element={<PaymentMethodFatoorah />}
            />
            <Route path="/find-activities" element={<FindActivities />} />
            <Route
              path="/kids-activities/:city/:category/:activityTitle"
              element={<Details />}
            />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/:slug" element={<BlogDetails />} />
            <Route path="/curated-list/:slug" element={<CuratedList />} />
            <Route path="awards" element={<Awards />} />
            <Route path="/the-kidzapp-awards-vote" element={<AwardsVote />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/cancellation-policy" element={<CancellationPolicy />} />
            <Route path="/voting-success" element={<VoteSuccess />} />
            <Route path="/kidzapp-tv" element={<KidzAppTv />} />
            <Route
              path="/kidzapp-tv/:category/:videoName/:id"
              element={<KidzAppTvDetail />}
            />
            <Route
              path="/kidzapp-tv/search/:query"
              element={<KidzAppTvSearch />}
            />
            <Route path="/my-bookings" element={<Mybookings />} />
            <Route path="/my-wishlist" element={<MyWishList />} />
            <Route path="/categories" element={<CategoriesProfile />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/how-the-app-works" element={<AppWorks />} />
            <Route
              path="/handpickedExperience"
              element={<AllhandpickedExperience />}
            />
            <Route path="/sitemap" element={<SiteMap />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </div>
  );
}
export default App;
