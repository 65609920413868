export const mockData = {
    "id": "Loading...",
    "uid": "Loading...",
    "name": "Loading...",
    "title": "Loading...",
    "title_en": "Loading...",
    "slug": "Loading...",
    "url": "Loading...",
    "backend_booking_type": "Loading...",
    "booking_payment_method": "Loading...",
    "booking_valid_until": "Loading...",
    "average_rating": 0,
    "profile2User": '',
    "ages_display": [],
    "address": "Loading...",
    "brief_address": "Loading...",
    "location": {
        "lat": '',
        "lon": ''
    },
    "show": true,
    "start_date": "Loading...",
    "end_date": "Loading...",
    "created_at": "Loading...",
    "description": "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ...",
    "meta_description": "",
    "image_url":  "https://fakeimg.pl/1200x400/",
    "image_carousel_list": [ '' ],
    "image_carousel_alt_list": [  ],
    "video_thumbnail": "",
    "price": [],
    "price_header": [
        {
            "cashback_total": 0,
            "cashback_highest": 0,
            "values": [
                {
                    "venue_price": 0.0,
                    "currency": "EGP",
                    "show_to_app": true,
                    "show_final_price": true,
                    "show_booking_button": true,
                    "is_cashback_active": false,
                    "cashback_amount": 0,
                    "cashback_start_date": null,
                    "cashback_end_date": null,
                    "type": "Workshop Fees",
                    "small_text_type": null
                }
            ]
        }
    ]
}