import { LazyLoadImage } from "react-lazy-load-image-component";
import purpleCalender from "../../../assets/images/calender-icon.svg";
import purpleCall from "../../../assets/images/phone-icon.svg";
import purpleSchedule from "../../../assets/images/calender-plus-icon.svg";
import purpleWebsite from "../../../assets/images/web-earth-icon.svg";
function Guarantee({ guarantee, className }) {
  return (
    <>
      <div
        className={`booking-page-content booking-page-detail-content ${
          className ? className : ""
        }`}
      >
        <div className="guarantee-text-section">
          <ul className="guarantee-text-wrap d-flex align-items-md-center justify-content-between">
            <li className="guarantee-text ">
              <a
                className="d-flex align-items-center flex-column"
                href={`tel:${guarantee?.phone}`}
              >
                <LazyLoadImage alt="Call Now" src={purpleCall} />
                <span>Call Now</span>
              </a>
            </li>
            {guarantee?.working_hours?.length > 0 ? (
              <>
                <li className="guarantee-text">
                  <button
                    type="button"
                    className="btn btn-primary d-flex align-items-center flex-column"
                    data-bs-toggle="modal"
                    data-bs-target="#viewSchedule"
                  >
                    <LazyLoadImage alt="view Schedule" src={purpleSchedule} />
                    <span>View Schedule</span>
                  </button>
                </li>
              </>
            ) : (
              <></>
            )}
            {guarantee?.website?.length > 0 ? (
              <>
                <li className="guarantee-text ">
                  <a
                    className="d-flex align-items-center flex-column"
                    href={guarantee?.website}
                    target="_blank"
                  >
                    <LazyLoadImage alt="view website" src={purpleWebsite} />
                    <span>View Website</span>
                  </a>
                </li>
              </>
            ) : (
              <></>
            )}
            {guarantee ? (
              <>
                <li className="guarantee-text d-flex align-items-center flex-column">
                  <button
                    type="button"
                    className="btn btn-primary d-flex align-items-center flex-column"
                    data-bs-toggle="modal"
                    data-bs-target="#addCalender"
                  >
                    <LazyLoadImage alt="add Calender" src={purpleCalender} />
                    <span>Add to Calendar</span>
                  </button>
                </li>
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
export default Guarantee;
