import { Link } from "react-router-dom";
import API from "../../../services/ApiService";
import { ApiEndPoint } from "../../../services/ApiEndPoint";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import walletIconSmall from "../../../assets/images/wallet.png";
import { formatCurrency } from "../../../services/CurrencyFormatter";
function Offers({ ...data }) {
  var language = localStorage.getItem("lang");
  const [inclusions, setInclusions] = useState([]);
  const [exclusions, setExclusions] = useState([]);
  const [scroll, setScroll] = useState(false);
  const getInclusionExclusion = (id) => {
    const config = {
      headers: {
        "Accept-Language": language,
      },
    };

    API.get(
      ApiEndPoint.relatedExp +
        "/price-option-group/" +
        id +
        "/inclusions_and_exclusions/",
      config
    )
      .then((response) => {
        console.log(response.data);
        setInclusions(response.data.inclusions);
        setExclusions(response.data.exclusions);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        setScroll(window.scrollY > 540);
      },
      { passive: true }
    );
  }, []);
  return (
    <>
      <div className={`${data?.className ? data?.className : ""}`}>
        <h2 className="review-title mb-3">
          <span>{`${data?.activityData.title}`} </span> Prices
        </h2>
        <div
          // className={isReached  ? 'scrolled booking-sidebar bottom-scroll' : 'scrolled booking-sidebar'}
          className={`${
            scroll ? "scrolled booking-sidebar" : "booking-sidebar"
          } `}
        >
          <div className="offer-wraper">
            {data?.priceHeader ? (
              <>
                {data?.priceHeader.map((item, index) => {
                  return (
                    <div className="experience-package">
                      <div className="package-header">
                        <h2 className="package-title">{item.header}</h2>
                        <p>{item?.description}</p>
                      </div>
                      <div className="package-content">
                        {item.values.map((itemm, index) => {
                          return (
                            <div>
                              <div className="package-info">
                                <div className="package-name">
                                  <h6>{itemm.type}</h6>
                                  <p>{itemm.small_text_type}</p>
                                </div>
                                <div className="package-amount">
                                  <p className="cashback-offer">
                                    <LazyLoadImage
                                      src={walletIconSmall}
                                      alt="pwalletackage icon"
                                    />{" "}
                                    Cashback{" "}
                                    <strong>
                                      {itemm.orginal_price - itemm.final_price >
                                      0
                                        ? formatCurrency(
                                            itemm.orginal_price -
                                              itemm.final_price,
                                            itemm.currency
                                          )
                                        : 0}{" "}
                                    </strong>
                                  </p>
                                  {itemm.orginal_price === itemm.final_price ? (
                                    <>
                                      <p className="mb-0 text-end">
                                        <span>
                                          {itemm.currency} {itemm.final_price}
                                        </span>
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="mb-0 text-end">
                                        <del>
                                          {itemm.currency} {itemm.orginal_price}
                                        </del>
                                        <span>
                                          {itemm.currency} {itemm.final_price}
                                        </span>
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="package-includes">
                          {item.show_inclusion_and_exclusion_btn ? (
                            <>
                              <button
                                className="btn btn-secondary mt-3"
                                type="button"
                                onClick={() => {
                                  getInclusionExclusion(item?.option_group_id);
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#footerSchedule"
                              >
                                View Inclusions & Exclusions
                              </button>
                            </>
                          ) : (
                            <div></div>
                          )}
                          <Link
                            onClick={() => {
                              localStorage.removeItem("datePicked");
                              localStorage.removeItem("bookings");
                            }}
                            to={
                              data?.activityData.backend_booking
                                ? `/bookings/${data?.activityId}`
                                : data?.activityData.booking_url
                            }
                            className="book-btn btn-primary"
                          >
                            Book now
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Footer Inclusion/Exclusion popup */}
      <div
        className="modal detail-modal detail-modal-white modals inclusion-exclusion-modal sdgsd"
        id="footerSchedule"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Inclusions</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row inclusions" id="inclusions">
                <div className="col-md-12 data-inclusions d-flex flex-wrap">
                  {inclusions?.length}{" "}
                  {inclusions.length > 0 ? (
                    <>
                      {inclusions.map((item, index) => {
                        return <p key={item.id}>{item.inclusions}</p>;
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <div className="col-md-12 data-inclusions d-flex flex-wrap">
                  {inclusions?.length > 0 ? (
                    <>
                      {inclusions.slice(0, 2).map((item, index) => {
                        return <p key={item.id}>{item.text}</p>;
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </div> */}
                <div className="col-md-12 mt-5">
                  {console.log(exclusions, "exclusions data")}

                  {exclusions.length > 0 ? (
                    <>
                      <h2
                        className="modal-title exclusion-title"
                        id="exampleModalLabel"
                      >
                        Exclusions
                      </h2>
                      <div className="col-md-12 data-exclusions d-flex flex-wrap">
                        {exclusions.map((item, index) => {
                          return <p key={item.id}>{item.text}</p>;
                        })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Offers;
