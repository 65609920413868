import axios from 'axios';
export default axios.create({
    baseURL: 'https://api2.kidzapp.com',
    responseType: "json",
    timeout: 20000,
    timeoutErrorMessage:'Server Timeout'
  });


  // stagurl:"https://api2.kidzapp.com"
  // liveUrl:"https://api2.kidzapp.com"
