// apiSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/ApiService";
import { ApiEndPoint } from "../../services/ApiEndPoint";
// Define your initial state
const initialState = {
  dataFromApi1: [],
  dataFromApi2: [],
  dataFromApi3: [],
  dataFromBlogs: [],
  dataFromCategories: [],
  dataFromSubscribeLetter: null,
  loading1: false,
  loading2: false,
  loading3: false,
  loading4: false,
  loading5: false,
  loading6: false,
  error1: null,
  error2: null,
  error3: null,
  error4: null,
  error5: null,
  error6: null,
};
var countryCode = localStorage.getItem("country_code");
var language = localStorage.getItem("lang");
var country_code = countryCode ? countryCode : "ae";
const config = {
  headers: {
    "Accept-Language": language,
  },
};

const fetchApi1Data = createAsyncThunk("api/fetchApi1Data", async () => {
  var internalName =
    country_code === "ae"
      ? "featured_banner_uae"
      : country_code === "eg"
      ? "featured_banner_eg"
      : country_code === "ca"
      ? "featured_banner_ca"
      : "featured_banner_uae";

  return API.get(
    ApiEndPoint.handpickedExperience +
      "?list_name=" +
      internalName +
      "&country_code=" +
      country_code +
      "&page=1&page_size=9&website=1&lang=" +
      language +
      "",
    config
  ).then((response) => {
    return response.data.results;
  });
});

const fetchApi2Data = createAsyncThunk("api/fetchApi2Data", async () => {
  // Replace with your API call for the second API
  return API.get(
    ApiEndPoint.handpickedExperience +
      "?list_name=trending_experiences&country_code=" +
      country_code +
      "&page=1&page_size=8&website=1&lang=" +
      language +
      "",
    config
  ).then((response) => {
    return response.data.results;
  });
});

const fetchApiCollection = createAsyncThunk(
  "api/fetchApiCollection",
  async () => {
    return API.get(
      ApiEndPoint.listCollection + "?country_code=" + country_code + "",
      config
    ).then((response) => {
      let collectionResults = response.data;
      const filtered = collectionResults.filter((ele) => {
        return ele.create_button === true;
      });

      return filtered;
    });
  }
);

const getBlogsAPi = createAsyncThunk("api/getBlogsAPi", async () => {
  return API.get(
    ApiEndPoint.blogs + "?limit=6&country_code=" + country_code,
    config
  ).then((response) => {
    return response.data.results;
  });
});

const getCategoriesAPi = createAsyncThunk("api/getCategoriesAPi", async () => {
  return API.get(
    ApiEndPoint.categories + "?country_code=" + country_code + "",
    config
  ).then((response) => {
    return response.data;
  });
});

const subscribeLetterAPi = createAsyncThunk(
  "api/subscribeLetterAPi",
  async (payload) => {
    // return API.get(ApiEndPoint.categories + "?country_code=" + country_code + "",config)
    // .then((response) => {
    //    return response.data
    // })

    return await API.post(ApiEndPoint.subscribeLetter, {
      email: payload,
      country_code: country_code,
    }).then((response) => {
      var data = "success";
      return data;
    });
  }
);

// Create a slice
const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle API 1 data
    builder
      .addCase(fetchApi1Data.pending, (state) => {
        state.loading1 = true;
        state.error1 = null;
      })
      .addCase(fetchApi1Data.fulfilled, (state, action) => {
        state.loading1 = false;
        state.dataFromApi1 = action.payload;
      })
      .addCase(fetchApi1Data.rejected, (state, action) => {
        state.loading1 = false;
        state.error1 = action.error.message;
      });

    // Handle API 2 data
    builder
      .addCase(fetchApi2Data.pending, (state) => {
        state.loading2 = true;
        state.error2 = null;
      })
      .addCase(fetchApi2Data.fulfilled, (state, action) => {
        state.loading2 = false;
        state.dataFromApi2 = action.payload;
      })
      .addCase(fetchApi2Data.rejected, (state, action) => {
        state.loading2 = false;
        state.error2 = action.error.message;
      });

    // Handle API 3 data
    builder
      .addCase(fetchApiCollection.pending, (state) => {
        state.loading3 = true;
        state.error3 = null;
      })
      .addCase(fetchApiCollection.fulfilled, (state, action) => {
        state.loading3 = false;
        state.dataFromApi3 = action.payload;
      })
      .addCase(fetchApiCollection.rejected, (state, action) => {
        state.loading3 = false;
        state.error3 = action.error.message;
      });

    // Handle API 4 data
    builder
      .addCase(getBlogsAPi.pending, (state) => {
        state.loading4 = true;
        state.error4 = null;
      })
      .addCase(getBlogsAPi.fulfilled, (state, action) => {
        state.loading4 = false;
        state.dataFromBlogs = action.payload;
      })
      .addCase(getBlogsAPi.rejected, (state, action) => {
        state.loading4 = false;
        state.error4 = action.error.message;
      });

    // Handle API 5 data
    builder
      .addCase(getCategoriesAPi.pending, (state) => {
        state.loading5 = true;
        state.error5 = null;
      })
      .addCase(getCategoriesAPi.fulfilled, (state, action) => {
        state.loading5 = false;
        state.dataFromCategories = action.payload;
      })
      .addCase(getCategoriesAPi.rejected, (state, action) => {
        state.loading5 = false;
        state.error5 = action.error.message;
      });

    // Handle API 6 data
    builder
      .addCase(subscribeLetterAPi.pending, (state) => {
        state.loading6 = true;
        state.error6 = null;
      })
      .addCase(subscribeLetterAPi.fulfilled, (state, action) => {
        state.loading6 = false;
        state.dataFromSubscribeLetter = action.payload;
      })
      .addCase(subscribeLetterAPi.rejected, (state, action) => {
        state.loading6 = false;
        state.error6 = action.error.message;
      });
  },
});

// Export async thunks and reducer actions
export {
  fetchApi1Data,
  fetchApi2Data,
  fetchApiCollection,
  getBlogsAPi,
  getCategoriesAPi,
  subscribeLetterAPi,
};

// Export the reducer
export default apiSlice.reducer;
