import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useLocation } from "react-router-dom";
import "./header.css";
import logoMain from "../../assets/images/logo.png";
import logoLogin from "../../assets/images/footer-logo.webp";
import kidsJump from "../../assets/images/kid.svg";
import envIcon from "../../assets/images/env.svg";
import logoWhite from "../../assets/images/logo-white.svg";
import accountProfile from "../../assets/images/profile.svg";
import bookingProfile from "../../assets/images/booking.svg";
import wishlistProfile from "../../assets/images/wishlist.webp";
import categoriesProfile from "../../assets/images/categories.svg";
import logoutProfile from "../../assets/images/logout.svg";
import walletProfile from "../../assets/images/wallet-icon.svg";
import headerSearchIcon from "../../assets/images/header-search-icon.svg";
import { useEffect, useLayoutEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { ApiEndPoint } from "../../services/ApiEndPoint";
import API from "../../services/ApiService";
import FacebookLogin from "react-facebook-login";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";

// import AppleLogin from "react-apple-login";
// import { GoogleLogin } from "@react-oauth/google";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { useCountry } from "../../context/CountryContext";

function Header() {
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);
  const [country, setCountry] = useState("UAE");
  const [language, setLanguage] = useState("English");
  const [loggedIn, setLoggedIn] = useState(false);
  const [shortname, setShortName] = useState("");
  const [errormsg, setErrorMsg] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const { setSelectedCountry } = useCountry();

  const changeLang = (l) => {
    return () => {
      i18n.changeLanguage(l);

      //Now set the current language in local storage
      localStorage.setItem("lang", l);
      localStorage.removeItem("bannerSliderResponse");

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    };
  };

  const setNavExpanded = () => {
    setIsExpand(!isExpand);
  };
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    setIsExpand(false);
  }, [location.pathname]);

  useEffect(() => {
    let code = localStorage.getItem("country_code");
    let lang = localStorage.getItem("lang");
    let loggedIn = localStorage.getItem("loggedIn");
    let usermail = localStorage.getItem("userEmail");

    if (usermail !== null) {
      let name = usermail.substring(0, usermail.lastIndexOf("@"));
      const noSpecialChars = name.replace(/[^A-Za-z]+/g, "");
      var output = noSpecialChars.slice(0, 1) + noSpecialChars.slice(-1);
      setShortName(output);
    }

    if (loggedIn == null) {
      setLoggedIn(false);
    } else if (loggedIn) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    if (lang == null) {
      setLanguage("English");
    } else if (lang === "en") {
      setLanguage("English");
    } else if (lang === "ar") {
      setLanguage("Arabic");
    }

    if (code == null) {
      setCountry("UAE");
    } else if (code === "ca") {
      setCountry("CANADA");
    } else if (code === "eg") {
      setCountry("EGYPT");
    }
    if (loggedIn) {
      getUserProfile();
    }
  }, []);

  const saveCountry = (code) => {
    localStorage.removeItem("bannerSliderResponse");
    localStorage.setItem("country_code", code);
    setSelectedCountry(code);
    location.pathname = "/";
  };

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    phoneNum: "",
    userBalance: [],
    profilePic: "",
  });
  const [s3ImageUrl, setS3ImageUrl] = useState("");
  let accessToken = localStorage.getItem("userAccessToken");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const confirmEmailHide = () => setShow2(false);
  const confirmEmailShow = () => {
    setShow2(true);
    setShow(false);
  };
  const confirmVerifyShow = () => setShow1(true);

  const getUserProfile = async () => {
    const config = {
      headers: {
        Authorization: "Bearer" + " " + accessToken,
      },
    };
    await API.get(ApiEndPoint.getUserProfile, config)
      .then((response) => {
        setUser({
          ...user,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          phoneNum: response.data.phone,
          userBalance: response.data.balance,
          profilePic: response.data.profile_picture_url,
        });
        localStorage.setItem("userHomelat", response.data.home.location.lat);
        localStorage.setItem("userHomelon", response.data.home.location.lng);

        setS3ImageUrl(response.data.profile_picture_url);
      })
      .catch((error) => {
        console.log("error userpofile", error);
      });
  };

  const customEmailLogin = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let validEmail = emailRegex.test(email);
    if (validEmail) {
      API.post(ApiEndPoint.customEmailLogin, {
        email: email,
        sdk: "web",
      })
        .then((response) => {
          localStorage.setItem("userEmail", email);
          confirmVerifyShow();
          verifyEmail();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setErrorMsg("Please enter valid email");
    }
  };

  const verifyEmail = () => {
    setLoading(true);
    API.post(ApiEndPoint.verifyEmail, {
      email: email,
    })
      .then((response) => {
        if (response.data.status == "False") {
          setTimeout(function () {
            verifyEmail(email);
          }, 5000);
        } else {
          localStorage.setItem("userAccessToken", response.data.access_token);
          localStorage.setItem("loggedIn", true);
          setLoggedIn(true);
          setShow1(false);
          setShow2(false);
          setShow(false);
          setLoading(false);
          location.pathname = "/myaccount";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const responseFacebook = (response) => {
    loginWithfacebook(response);
  };

  const loginWithfacebook = async (data) => {
    var language = localStorage.getItem("lang");

    const config = {
      headers: {
        "Accept-Language": language,
      },
    };
    await API.post(
      ApiEndPoint.getUserProfile,
      {
        facebook_access_token: data.accessToken,
      },
      config
    )
      .then((response) => {
        localStorage.setItem("userEmail", response.data.user.email);
        localStorage.setItem("userAccessToken", response.data.token);
        localStorage.setItem("loggedIn", true);
        location.pathname = "/myaccount";
      })
      .catch((error) => {
        console.log("error userpofile", error);
      });
  };

  const onLogout = () => {
    localStorage.clear();
    location.pathname = "/";
    API.post(ApiEndPoint.logout, {
      email: email,
    })
      .then((response) => { })
      .catch((error) => {
        console.log(error);
      });
  };

  const goforsearch = () => {
    if (searchKeyword.length > 0) {
      window.location.href = "/find-activities/?search=" + searchKeyword;
      setSearchKeyword("");
    } else {
      alert("Pleaseenter search keyword");
    }
  };

  const responseMessage = (response) => {
    console.log("response google", response);
  };
  const errorMessage = (error) => {
    console.log("error google", error);
  };
  return (
    <header className="header main-header">
      <div className="header-top-bar">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} sm={5}>
              <ul className="social-links-top d-sm-flex d-none mb-3 mb-sm-0 align-items-center">
                <li className="link-item">
                  <NavLink
                    className="nav-link"
                    aria-label="Read more about kidzapp facebook page"
                    to="https://www.facebook.com/kidzappAE/"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="fa-brands fa-facebook" />
                  </NavLink>
                </li>
                <li className="link-item">
                  <NavLink
                    className="nav-link"
                    aria-label="Read more about kidzapp twitter page"
                    to="https://twitter.com/i/flow/login?redirect_after_login=%2Fkidzapp2%2F"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="fa-brands fa-twitter" />
                  </NavLink>
                </li>
                <li className="link-item">
                  <NavLink
                    className="nav-link"
                    aria-label="Read more about kidzapp whatsapp"
                    to="https://api.whatsapp.com/send?phone=201204133928&text=Hi%2C+I%27m+browsing+home+screen+and+have+some+questions"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                  </NavLink>
                </li>
                <li className="link-item">
                  <NavLink
                    className="nav-link"
                    aria-label="Read more about kidzapp instagram account"
                    to="https://www.instagram.com/kidzappuae/"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                  </NavLink>
                </li>
                <li className="link-item">
                  <NavLink
                    className="nav-link"
                    aria-label="Read more about kidzapp youtube channel"
                    to="https://www.youtube.com/channel/UCcWhXfXzQ8cbVvJC3cGgu2Q"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="fa-brands fa-youtube" />
                  </NavLink>
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={7}>
              <ul className="language-options d-flex">
                <li className="link-item">
                  <NavLink
                    className="nav-link"
                    to="https://play.google.com/store/apps/details?id=com.kidzapp"
                    target="_blank"
                  >
                    <LazyLoadImage
                      width="12px"
                      height="15px"
                      alt="website logo"
                      src={
                        "https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/mobile.svg"
                      }
                    />
                    {t("download_app")}
                  </NavLink>
                </li>
                <li className="link-item d-flex align-items-center">
                  <LazyLoadImage
                    width="18px"
                    height="18px"
                    alt="website logo"
                    src={
                      country === "UAE"
                        ? "https://drfsb8fjssbd3.cloudfront.net/images/Flag-UAE.svg"
                        : country === "EGYPT"
                          ? "https://drfsb8fjssbd3.cloudfront.net/images/egy.svg"
                          : "https://kidzapp.com/images/canada.svg"
                    }
                  />
                  <NavDropdown
                    align={{ lg: "start" }}
                    title={country}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item
                      onClick={() => {
                        setCountry("UAE");
                        saveCountry("ae");
                      }}
                    >
                      UAE
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        setCountry("EGYPT");
                        saveCountry("eg");
                      }}
                    >
                      EGYPT
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        setCountry("CANADA");
                        saveCountry("ca");
                      }}
                    >
                      CANADA
                    </NavDropdown.Item>
                  </NavDropdown>
                </li>
                <li className="link-item d-flex align-items-center">
                  <LazyLoadImage
                    width="16px"
                    height="16px"
                    alt="website logo"
                    src={
                      "https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/Group5406.svg"
                    }
                  />
                  {language === "Arabic" ? (
                    <Button
                      className="nav-link"
                      onClick={changeLang("en")}
                      id="basic-nav-dropdown"
                    >
                      English
                    </Button>
                  ) : (
                    <Button
                      className="nav-link"
                      onClick={changeLang("ar")}
                      id="basic-nav-dropdownar"
                    >
                      عربي
                    </Button>
                  )}
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar
        sticky="top"
        expand="xl"
        onToggle={setNavExpanded}
        expanded={isExpand}
      >
        <Container>
          <Navbar.Brand href="/">
            <LazyLoadImage
              className="home-logo"
              width="154px"
              height="33px"
              alt="website logo"
              src={logoWhite}
            />
            <LazyLoadImage
              className="inner-page-logo"
              width="154px"
              height="33px"
              alt="website logo"
              src={logoMain}
            />
          </Navbar.Brand>
          {loggedIn ? (
            // <NavLink className="user-icon d-inline-block d-xl-none nav-link d-flex align-items-center justify-content-center" to="myaccount">{shortname.toUpperCase()}</NavLink>
            <Dropdown className="d-flex ms-auto">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="d-md-none"
              >
                <div className="user-icon nav-link user-image ">
                  {user.profilePic ? (
                    <LazyLoadImage src={user?.profilePic} />
                  ) : (
                    <span>{shortname.toUpperCase()}</span>
                  )}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="myaccount-dropdown-menu responsive d-md-none">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Go back
                </Dropdown.Toggle>

                <div className="account-header py-3 px-2">
                  <div className="profile-picture">
                    <LazyLoadImage
                      src={
                        s3ImageUrl.length > 0
                          ? s3ImageUrl
                          : "https://drfsb8fjssbd3.cloudfront.net/images/user-facebook-kidzapp.svg"
                      }
                    />
                  </div>

                  {user.userBalance.length > 0 ? (
                    <>
                      <div className="bg-green border py-2 px-3 rounded-pill text-white f-12 fw-bold d-flex align-items-center ff-inter">
                        {user.userBalance[0].balance}{" "}
                        {user.userBalance[0].currency}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="myaccount-subheader pt-0">
                  <div className="flex-column">
                    <LazyLoadImage
                      className="filter-img-white"
                      src={logoWhite}
                      alt=""
                    />
                    <br />
                    <div className="text-white fs-20">
                      Hello {user.firstName} {user.lastName}!
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="myaccount-links">
                  <Dropdown.Item href="/myaccount">
                    <LazyLoadImage className="pe-3" src={accountProfile} />
                    {t("my_profile")}
                  </Dropdown.Item>
                  <Dropdown.Item href="/my-bookings">
                    <LazyLoadImage className="pe-3" src={bookingProfile} />
                    {t("my_bookings")}
                  </Dropdown.Item>
                  <Dropdown.Item href="/my-wishlist">
                    <LazyLoadImage className="pe-3" src={wishlistProfile} />
                    {t("my_wishlist")}
                  </Dropdown.Item>
                  <Dropdown.Item href="/categories">
                    <LazyLoadImage className="pe-3" src={categoriesProfile} />
                    {t("categories")}
                  </Dropdown.Item>
                  <Dropdown.Item href="/wallet">
                    <LazyLoadImage className="pe-3" src={walletProfile} />
                    {t("wallet")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="javascript:void(0)"
                    onClick={() => {
                      onLogout();
                    }}
                  >
                    <LazyLoadImage className="pe-3" src={logoutProfile} />
                    {t("logout")}
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button
              className="d-inline-block d-xl-none"
              variant="primary"
              onClick={handleShow}
            >
              {t("login")}
            </Button>
          )}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse expand={`${isExpand}`} id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavLink className="nav-link" to="find-activities">
                {t("find_act")}
              </NavLink>
              <NavLink className="nav-link" to="blogs">
                {t("blog")}
              </NavLink>
              <NavLink className="nav-link" to="awards">
                {t("kidzapp_awards")} <span className="nav-flag">new</span>{" "}
              </NavLink>
              <NavLink className="nav-link" to="kidzapp-tv">
                {t("kidzapp_tv")}
              </NavLink>
              <NavLink className="nav-link" to="get-in-touch">
                {t("get_in_touch")}
              </NavLink>
              <li className="link-item nav-link d-xl-inline-block d-none search-opt-items">
                <LazyLoadImage
                  width="40px"
                  height="40px"
                  alt="website logo"
                  src={headerSearchIcon}
                />
                <NavDropdown align={{ lg: "start" }} id="basic-nav-dropdown">
                  <div className="form-group">
                    <input
                      className="form-cotrol"
                      placeholder="search"
                      value={searchKeyword}
                      onChange={(e) => {
                        setSearchKeyword(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          goforsearch();
                        }
                      }}
                    />
                    <button
                      className="btn"
                      onClick={() => {
                        goforsearch();
                      }}
                    >
                      <LazyLoadImage
                        width="40px"
                        height="40px"
                        alt="website logo"
                        src={headerSearchIcon}
                      />
                    </button>
                  </div>
                </NavDropdown>
              </li>

              {loggedIn ? (
                <Dropdown className="d-none d-lg-block">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="user-icon nav-link">
                      {user.profilePic ? (
                        <LazyLoadImage src={user?.profilePic} />
                      ) : (
                        <span>{shortname.toUpperCase()}</span>
                      )}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="myaccount-dropdown-menu">
                    <div className="account-header py-3">
                      <div className="profile-picture">
                        <LazyLoadImage
                          src={
                            s3ImageUrl.length > 0
                              ? s3ImageUrl
                              : "https://drfsb8fjssbd3.cloudfront.net/images/user-facebook-kidzapp.svg"
                          }
                        />
                      </div>

                      {user.userBalance.length > 0 ? (
                        <>
                          <div className="bg-green border py-2 px-3 rounded-pill text-white f-12 fw-bold d-flex align-items-center ff-inter">
                            {user.userBalance[0].balance}{" "}
                            {user.userBalance[0].currency}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="myaccount-subheader pt-0">
                      <div className="flex-column">
                        <LazyLoadImage
                          className="filter-img-white"
                          src={logoWhite}
                          alt=""
                        />
                        <br />
                        <div className="text-white fs-20">
                          Hello {user.firstName} {user.lastName}!
                        </div>
                      </div>
                      <hr />
                    </div>
                    <div className="myaccount-links">
                      <Dropdown.Item href="/myaccount">
                        <LazyLoadImage className="pe-3" src={accountProfile} />
                        {t("my_profile")}
                      </Dropdown.Item>
                      <Dropdown.Item href="/my-bookings">
                        <LazyLoadImage className="pe-3" src={bookingProfile} />
                        {t("my_bookings")}
                      </Dropdown.Item>
                      <Dropdown.Item href="/my-wishlist">
                        <LazyLoadImage className="pe-3" src={wishlistProfile} />
                        {t("my_wishlist")}
                      </Dropdown.Item>
                      <Dropdown.Item href="/categories">
                        <LazyLoadImage
                          className="pe-3"
                          src={categoriesProfile}
                        />
                        {t("categories")}
                      </Dropdown.Item>
                      <Dropdown.Item href="/wallet">
                        <LazyLoadImage className="pe-3" src={walletProfile} />
                        {t("wallet")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={() => {
                          onLogout();
                        }}
                      >
                        <LazyLoadImage className="pe-3" src={logoutProfile} />
                        {t("logout")}
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button variant="primary" onClick={handleShow}>
                  {t("login")}
                </Button>
              )}

              <Modal className="login-modal" show={show} onHide={handleClose}>
                <Modal.Body>
                  <div className="login-content">
                    <div className="login-text text-center">
                      <LazyLoadImage
                        width="92px"
                        height="20px"
                        alt="website logo"
                        src={logoLogin}
                        loading="lazy"
                      />
                      <span className="login-title d-block">
                        {t("login_signUp")}
                      </span>
                    </div>
                    <ul className="login-button-group d-flex flex-column align-items-center">
                      <li className="login-button">
                        <Button
                          variant="primary"
                          onClick={() => {
                            confirmEmailShow();
                            setErrorMsg("");
                          }}
                        >
                          <LazyLoadImage src={envIcon} alt="login with Email" />
                          {t("continue_with") + " " + t("email")}{" "}
                        </Button>
                      </li>
                      <li className="login-button">
                        <FacebookLogin
                          appId="231173804067588"
                          autoLoad={false}
                          fields="name, email"
                          callback={responseFacebook}
                        />
                      </li>

                      {/* <li className="login-button google-login-button">
                        <GoogleLogin
                          onSuccess={responseMessage}
                          onError={errorMessage}
                        />
                      </li> */}
                    </ul>
                    <p className="terms-text">
                      {t("by_using_kidzapp_you_agree_to_our")}
                      <NavLink to="/">{t("terms_and_conditions")}</NavLink>
                      {t("and")} <NavLink to="/">{t("privacy_policy")}</NavLink>
                    </p>
                  </div>
                </Modal.Body>
              </Modal>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        className="email-modal d-flex align-items-center jusify-content-center"
        show={show2}
        onHide={confirmEmailHide}
      >
        <Modal.Body>
          <div className="email-content">
            <div className="email-title-section">
              <LazyLoadImage
                className="kids-image"
                src={kidsJump}
                alt="Enter your Email ID"
                height="150px"
                width="120px"
                loading="lazy"
              />
              <h3 className="email-title">
                {t("enter_email_text")}
                <br /> <strong>{t("email_id")}</strong>
              </h3>
            </div>
            <Form className="text-center">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setErrorMsg("");
                    setEmail(e.target.value);
                  }}
                  placeholder="johnsnow@gmail.com"
                />
                <p>{errormsg}</p>
              </Form.Group>
              <Button
                onClick={() => {
                  customEmailLogin();
                }}
                variant="primary"
              >
                {t("submit")}
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="email-modal d-flex align-items-center jusify-content-center"
        show={show1}
        onHide={confirmEmailHide}
      >
        <Modal.Body>
          <div className="email-content d-none">
            <div className="email-title-section">
              <LazyLoadImage
                className="kids-image"
                src={kidsJump}
                alt="Enter your Email ID"
                height="150px"
                width="120px"
                loading="lazy"
              />
              <h3 className="email-title">
                {t("verify_your")}
                <br /> <strong>{email}</strong>
              </h3>
            </div>
            <Form className="text-center">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="email" placeholder="johnsnow@gmail.com" />
              </Form.Group>
              <Button variant="primary" type="submit">
                {t("submit")}
              </Button>
            </Form>
          </div>
          <div className="email-content text-center resend-content-mail">
            <div className="email-title-section">
              <LazyLoadImage
                className="kids-image"
                src={kidsJump}
                alt="Enter your Email ID"
                height="150px"
                width="120px"
                loading="lazy"
              />
              <h3 className="email-title">
                {t("verify_your")} <br /> <strong>{t("email_id")}</strong>
              </h3>
            </div>
            <p>
              <a href={"mailto:" + email}>{email}</a>
            </p>
            <p className="text-muted">
              {t("please_open_your_email_and_confirm_your_email_address")}
            </p>
            <NavLink
              className="nav-link"
              to="https://mail.google.com/mail/u/0/#inbox"
              target="_blank"
            >
              <Button variant="primary">{t("open_your_mail")}</Button>
            </NavLink>
            {loading ? (
              <div className="bubble-loader mt-2">
                <div className="login-bubble-dots"></div>
                <div className="login-bubble-dots"></div>
                <div className="login-bubble-dots"></div>
              </div>
            ) : null}

            <p className="resend-mail">
              <span
                onClick={() => {
                  setShow1(false);
                  setShow2(true);
                }}
              >
                {t("resend_email")}
              </span>
            </p>
            <p className="text-muted text-bottom">{t("check_spam")}</p>
          </div>
        </Modal.Body>
      </Modal>
    </header>
  );
}

export default trackWindowScroll(Header);
