import React from "react";

export const lazyLoadComponent = React.lazy;

// export const Header = lazyLoadComponent(() => import("./components/header/header"));
// export const Home = lazyLoadComponent(() =>
//   import("./pages/home/home")
// );

export const Centers = lazyLoadComponent(() =>
  import("./pages/centers/centers")
);

export const MyAccount = lazyLoadComponent(() => import("./pages/myAccount"));

export const AboutUs = lazyLoadComponent(() => import("./pages/ourStory"));

export const GetInTouch = lazyLoadComponent(() => import("./pages/getInTouch"));

export const Boookings = lazyLoadComponent(() => import("./pages/boookings"));

export const BookingReview = lazyLoadComponent(() =>
  import("./pages/booking-review")
);

export const PaymentMethod = lazyLoadComponent(() =>
  import("./pages/payment-method")
);

// export const Details = lazyLoadComponent(() => import("./pages/details"));

export const PaymentMethodFatoorah = lazyLoadComponent(() =>
  import("./components/payment/paymentMethod")
);

export const FindActivities = lazyLoadComponent(() =>
  import("./pages/activities")
);

export const ViewBooking = lazyLoadComponent(() =>
  import("./pages/view-booking")
);

export const Blogs = lazyLoadComponent(() => import("./pages/blogs"));

export const BlogDetails = lazyLoadComponent(() =>
  import("./pages/blog-details")
);

export const Awards = lazyLoadComponent(() => import("./pages/awards"));

export const AwardsVote = lazyLoadComponent(() =>
  import("./pages/awards-vote")
);

export const PrivacyPolicy = lazyLoadComponent(() =>
  import("./pages//privacy-policy")
);

export const VoteSuccess = lazyLoadComponent(() =>
  import("./pages/vote-success")
);

export const KidzAppTv = lazyLoadComponent(() => import("./pages/kidzapp-tv"));

export const KidzAppTvDetail = lazyLoadComponent(() =>
  import("./pages/kidzapp-tv-detail")
);

export const KidzAppTvSearch = lazyLoadComponent(() =>
  import("./pages/kidzapp-tv-search")
);

export const TermsAndConditions = lazyLoadComponent(() =>
  import("./pages/terms-and-cnditions")
);

export const CancellationPolicy = lazyLoadComponent(() =>
  import("./pages/cancellation-policy")
);

export const Mybookings = lazyLoadComponent(() => import("./pages/mybookings"));

export const MyWishList = lazyLoadComponent(() =>
  import("./pages/my-wishlist")
);

export const CategoriesProfile = lazyLoadComponent(() =>
  import("./pages/categories")
);

export const Wallet = lazyLoadComponent(() => import("./pages/wallet"));

export const AllhandpickedExperience = lazyLoadComponent(() =>
  import("./components/_home/HandpickedExperience/allhandpickedExperience")
);

export const CuratedList = lazyLoadComponent(() =>
  import("./pages/curated-list")
);

export const AppWorks = lazyLoadComponent(() => import("./pages/app-works"));

export const SiteMap = lazyLoadComponent(() => import("./pages/site-map"));

export const AdyenPayment = lazyLoadComponent(() =>
  import("./components/adhyenPayment/adhyenPayment")
);
