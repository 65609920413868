import React, { lazy, Suspense } from "react";

import Banner from "../../components/_home/Banner/banner";
import Loader from "../../components/_home/Loader/loader";
import { useEffect } from "react";
import "./home.css";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const Collection = lazy(() =>
  import("../../components/_home/Collection/collection")
);
const LatestBlogs = lazy(() =>
  import("../../components/_home/LatestBlogs/latest-blogs")
);
const Categories = lazy(() =>
  import("../../components/_home/Categories/categories")
);
const Testimonials = lazy(() =>
  import("../../components/_home/Testimonials/testimonials")
);
const Feed = lazy(() => import("../../components/_home/Feeds/feeds"));
const HandpickedExperience = lazy(() =>
  import("../../components/_home/HandpickedExperience/handpicked-experience")
);
const BannerSlider = lazy(() =>
  import("../../components/_home/BannerSlider/banner-slider")
);
const Trending = lazy(() => import("../../components/_home/Trending/trending"));
// const Recommended = lazy(() =>
//   import("../../components/_home/Recommended/recommended")
// );

function Home() {
  useEffect(() => {
    document.body.classList.add("home-page");
    return () => {
      document.body.classList.remove("home-page");
    };
  }, []);
  return (
    <div className="wrapper-home loader-relative">
      <Banner />

      <Suspense fallback={<Loader />}>
        <BannerSlider />
        <HandpickedExperience />
        {/* <Recommended /> */}

    
        <Trending />
        <LazyLoadComponent>
        <Collection />
        <LatestBlogs />
       
          <Categories />
          <Feed />
        </LazyLoadComponent>
        <Testimonials />
     
      </Suspense>
    </div>
  );
}
export default Home;
