import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store.js";
import { Provider } from "react-redux";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faTwitterSquare, faTwitter, faWhatsapp, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPenToSquare,  faChevronRight, faChevronUp, faChevronLeft, faLongArrowRight , faFilter, faWallet, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { CountryProvider } from "./context/CountryContext.js";
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Provider store={store}>
      {/* <GoogleOAuthProvider clientId="369862170304-jju9k937e0bth7b19mgbv4qnihofr807.apps.googleusercontent.com"> */}
        <CountryProvider>
          <App />
        </CountryProvider>
      {/* </GoogleOAuthProvider> */}
    </Provider>
  </BrowserRouter>
);
library.add(faHeart,faFacebook,faTwitter, faTwitterSquare, faWhatsapp, faInstagram, faYoutube ,faPenToSquare,  faChevronRight, faChevronUp, faChevronLeft, faLongArrowRight , faFilter, faWallet, faChevronDown);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
