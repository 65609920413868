import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import clock from "../../assets/images/clock.svg";
import Location from "../../assets/images/location-activity.svg";
import LocationOr from "../../assets/images/location-orange.svg";
import offerBgIcon from "../../assets/images/offer-bg-icon.svg";
import profile2User from "../../assets/images/profile-2user.svg";
import walletWhite from "../../assets/images/wallet-white.png";
import thumbsDown from "../../assets/images/thumbs-down.svg";
import thumbsUp from "../../assets/images/thumbs-up.svg";
import replyIcon from "../../assets/images/reply.svg";
import distanceIcon from "../../assets/images/distance-icon.png";
import offerIcon from "../../assets/images/offer-icon.png";
import chevronDown from "../../assets/images/dropdown-arrow-grey.png";
import Button from "react-bootstrap/Button";
// import OwlCarousel from "react-owl-carousel";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useEffect, useState } from "react";
import API from "../../services/ApiService";
import { ApiEndPoint } from "../../services/ApiEndPoint";
import GoogleMapReact from "google-map-react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import Loader from "../../components/_home/Loader/loader";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import logoLogin from "../../assets/images/footer-logo.webp";
import kidsJump from "../../assets/images/kid.svg";
import envIcon from "../../assets/images/env.svg";
import simpleStarIcon from "../../assets/images/star_grey.svg";
import favouriteIcon from "../../assets/images/book-wish.svg";
import uploadIcon from "../../assets/images/book-share.svg";
import { RWebShare } from "react-web-share";
import FacebookLogin from "react-facebook-login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MetaTags from "../../components/metaTags/metaTags";
import arrowLeft from "../../assets/images/arrow-left.svg";
import walletIconSmall from "../../assets/images/wallet.png";
import { formatCurrency } from "../../services/CurrencyFormatter";
import starGrey from "../../assets/images/star_grey.svg";
import {
  Advertisement,
  Attractions,
  Guarantee,
  Indicators,
  MissingInformation,
  Offers,
  Overview,
  TermsAndConditions,
} from "./sub-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import { mockData } from "./mock";

function Details() {
  const [isReached, setIsReached] = useState(false);
  const currentLocation = window.location.href;

  useEffect(() => {
    const handleScroll = () => {
      const specificDiv = document.getElementById("yourSpecificDivId");
      if (specificDiv) {
        const { top } = specificDiv.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Adjust this value according to your needs for triggering the class addition
        const offset = 100;

        if (top - offset <= windowHeight) {
          setIsReached(true);
        } else {
          setIsReached(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true });
    };
  }, []);

  const { t } = useTranslation();
  const [show4, setShow4] = useState(false);
  var language = localStorage.getItem("lang");
  const handleClosee = () => setShow4(false);
  const handleShoww = () => setShow4(true);
  const optionsPosterSlider = {
    arrows: false,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const currentUrl = window.location.href;
  const navigate = useNavigate();

  const activityId = currentUrl.split("-").pop();
  const [activityData, setActivityData] = useState(mockData);
  const [linkedVenues, setLinkedVenues] = useState([]);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [priceHeader, setPriceHeader] = useState([]);
  const [relatedExp, setRelatedExp] = useState([]);
  const [workingHr, setWorkingHr] = useState([]);
  const [favourite, setFavourite] = useState(false);
  const [favouriteIds, setFavouriteIds] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [showReview, setReviewShow] = useState(false);
  const [reviewComment, setReviewComment] = useState("");
  const [currentReview, setCurrentReview] = useState("");
  const [times, setTimes] = useState([]);
  const [dates, setDates] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [otherOffers, setOtherOffers] = useState([]);
  const [missingInfoNotes, setMissingInfoNotes] = useState("");
  const [eventStopped, setEventStopped] = useState(false);
  const [notfamilies, setNotFamilies] = useState(false);
  const [siteNotWorking, setSiteNotWorking] = useState(false);
  const [wrongDateTime, setWrongDateTime] = useState(false);
  const [wrongAge, setWrongAge] = useState(false);
  const [reviewReplies, setReviewReplies] = useState([]);
  const [selctedIndex, setSelectedIndex] = useState(0);
  const [selectedIndexReplies, setSelectedIndexReplies] = useState(0);
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [userLat, setUserLat] = useState(0);
  const [userLong, setUserLong] = useState(0);
  const [distanceLoc, setDistanceLoc] = useState(0);
  const [reviewRating, setReviewRating] = useState(0);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewDes, setReviewDes] = useState("");
  const [reviewFile, setReviewFile] = useState("");
  const reviewRatings = [
    { id: 1, value: "Boring" },
    { id: 2, value: "Meh" },
    { id: 3, value: "Like it" },
    { id: 4, value: "Fun" },
    { id: 5, value: "Love it!" },
  ];
  const handleReviewClose = () => setReviewShow(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleReviewShow = (data) => {
    setCurrentReview(data);
    setReviewShow(true);
  };

  const [inclusions, setInclusions] = useState([]);
  const [exclusions, setExclusions] = useState([]);
  const [scroll, setScroll] = useState(false);
  const getInclusionExclusion = (id) => {
    const config = {
      headers: {
        "Accept-Language": language,
      },
    };

    API.get(
      ApiEndPoint.relatedExp +
        "/price-option-group/" +
        id +
        "/inclusions_and_exclusions/",
      config
    )
      .then((response) => {
        console.log(response.data);
        setInclusions(response.data.inclusions);
        setExclusions(response.data.exclusions);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        setScroll(window.scrollY > 540);
      },
      { passive: true }
    );
  }, []);

  let accessToken = localStorage.getItem("userAccessToken");
  var config;
  if (accessToken) {
    config = {
      headers: {
        "Accept-Language": language,
        Authorization: "Bearer" + " " + accessToken,
      },
    };
  } else {
    config = {
      headers: {
        "Accept-Language": language,
      },
    };
  }

  var countryCode = localStorage.getItem("country_code");
  // var country_code = countryCode ? countryCode : "ae";

  useEffect(() => {}, [reviews]);

  useEffect(() => {
    getExperiencebyId();
    getLinkedVenues();
    getRelatedExperiences();
    getReviews();
    getOffers();

    if (accessToken !== null) {
      getUserFavourite();
    }
  }, []);

  useEffect(() => {}, [reviewFile]);

  useEffect(() => {
    let lat = localStorage.getItem("lat");
    let long = localStorage.getItem("long");
    setUserLat(lat);
    setUserLong(long);
  }, []);

  useEffect(() => {}, [reviews]);

  const getExperiencebyId = () => {
    API.get(ApiEndPoint.getBookingbyDate + "/" + activityId + "/")
      .then((response) => {
        setActivityData(response.data);
        setWorkingHr(response.data?.working_hours);
        setLatitude(response.data.location.lat);
        setLongitude(response.data.location.lon);
        setPriceHeader(response.data?.price_header);
        setIndicators(response.data.indicators);
        let times = [];
        let schedule;
        schedule = response.data?.working_hours;
        if (schedule && schedule?.length > 0) {
          let startTime = moment(schedule[0]["open"], ["h:mm A"]).format(
            "HH:mm:ss"
          );
          let endTime = moment(schedule[0]["close"], ["h:mm A"]).format(
            "HH:mm:ss"
          );
          for (let i = 0; i < schedule?.length; i++) {
            if (
              startTime >
              moment(schedule[0]["open"], ["h:mm A"]).format("HH:mm:ss")
            ) {
              startTime = moment(schedule[0]["open"], ["h:mm A"]).format(
                "HH:mm:ss"
              );
            }
            if (
              endTime <
              moment(schedule[0]["close"], ["h:mm A"]).format("HH:mm:ss")
            ) {
              endTime = moment(schedule[0]["close"], ["h:mm A"]).format(
                "HH:mm:ss"
              );
            }
          }
          while (endTime > startTime) {
            times.push(startTime);
            startTime = addTimes(startTime, "00:30:00");
          }
        }
        function addTimes(startTime, endTime) {
          let times = [0, 0, 0];
          let max = times?.length;

          let a = (startTime || "").split(":");
          let b = (endTime || "").split(":");

          // normalize time values
          for (let i = 0; i < max; i++) {
            a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i]);
            b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i]);
          }

          // store time values
          for (let i = 0; i < max; i++) {
            times[i] = a[i] + b[i];
          }

          let hours = times[0];
          let minutes = times[1];
          let seconds = times[2];

          if (seconds >= 60) {
            let m = (seconds / 60) << 0;
            minutes += m;
            seconds -= 60 * m;
          }

          if (minutes >= 60) {
            let h = (minutes / 60) << 0;
            hours += h;
            minutes -= 60 * h;
          }
          return (
            ("0" + hours).slice(-2) +
            ":" +
            ("0" + minutes).slice(-2) +
            ":" +
            ("0" + seconds).slice(-2)
          );
        }

        convertToAMPM(times);
        getDates();
        distance();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function convertToAMPM(timeArray) {
    const formattedTimes = timeArray.map((timeString) => {
      const time = new Date(`2023-09-05T${timeString}`);
      const hours = time.getHours();
      const minutes = time.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";

      // Convert hours from 24-hour to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Ensure minutes are displayed as two digits (e.g., '05' instead of '5')
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    });
    setTimes(formattedTimes);
    return formattedTimes;
  }

  const getDates = () => {
    const config = {
      headers: {
        "Accept-Language": language,
      },
    };

    API.get(ApiEndPoint.events + "/" + activityId + "/schedule", config)
      .then((response) => {
        var data = response.data;
        var dateArray = [];
        for (var i = 0; i < data?.length; i++) {
          if (dateArray.includes(data[i].date)) {
          } else {
            dateArray.push(data[i].date);
          }
        }
        setDates(dateArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function formatDate(dateString) {
    const formattedDate = moment(dateString).format("dddd, MMM D, YYYY");
    return formattedDate;
  }

  const getLinkedVenues = () => {
    API.get(ApiEndPoint.linkedVenues + "/" + activityId)
      .then((response) => {
        setLinkedVenues(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRelatedExperiences = async () => {
    const config = {
      headers: {
        "Accept-Language": language,
      },
    };

    await axios
      .get(
        "https://api2.kidzapp.com/api/3.0/experiences/" +
          activityId +
          "/similar/?country_code=ae&page_size=4"
      )
      .then((response) => {
        setRelatedExp(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderMarker = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
      map,
      draggable: true,
      title: "User Location",
    });
    return marker;
  };

  const getReviews = () => {
    const config = {
      headers: {
        "Accept-Language": language,
      },
    };

    API.get(
      ApiEndPoint.relatedExp +
        "/" +
        activityId +
        "/reviews?order_by=newest&page=1&page_size=4",
      config
    )
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const markFavourite = () => {
    if (accessToken == null) {
      // alert("Please login first")
      handleShow();
    } else {
      const config = {
        headers: {
          Authorization: "Bearer" + " " + accessToken,
        },
      };
      if (favouriteIds.includes(parseInt(activityId))) {
        API.delete(ApiEndPoint.favourite + "/" + activityId, config)
          .then((response) => {
            window.location.reload();
            // window.location.reload()
            setFavourite(false);
            getUserFavourite();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        API.post(
          ApiEndPoint.favourite,
          {
            experience_id: activityId,
          },
          config
        )
          .then((response) => {
            setFavourite(true);
            getUserFavourite();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const getUserFavourite = () => {
    const config = {
      headers: {
        Authorization: "Bearer" + " " + accessToken,
      },
    };

    API.get(ApiEndPoint.favourite, config)
      .then((response) => {
        // window.location.reload()
        var data = response.data;
        for (var i = 0; i < data?.length; i++) {
          favouriteIds.push(data[i].id);
        }
        if (favouriteIds.includes(parseInt(activityId))) {
          setFavourite(true);
        } else {
          setFavourite(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const confirmEmailHide = () => setShow2(false);
  const confirmEmailShow = () => {
    setShow2(true);
    setShow(false);
  };
  const confirmVerifyShow = () => setShow1(true);

  const customEmailLogin = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let validEmail = emailRegex.test(email);
    if (validEmail) {
      API.post(ApiEndPoint.customEmailLogin, {
        email: email,
        sdk: "web",
      })
        .then((response) => {
          localStorage.setItem("userEmail", email);
          confirmVerifyShow();
          verifyEmail();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Please enter valid email");
    }
  };

  const verifyEmail = () => {
    setLoading(true);
    API.post(ApiEndPoint.verifyEmail, {
      email: email,
    })
      .then((response) => {
        if (response.data.status == "False") {
          setTimeout(function () {
            verifyEmail(email);
          }, 1000);
        } else {
          localStorage.setItem("userAccessToken", response.data.access_token);
          localStorage.setItem("loggedIn", true);
          setLoggedIn(true);
          setShow1(false);
          setShow2(false);
          setShow(false);
          setLoading(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const likeReview = (reviewId) => {
    if (accessToken == null) {
      handleShow();
    } else {
      API.post(
        ApiEndPoint.likeReview + reviewId + "/upvote",
        {
          like: true,
        },
        config
      )
        .then((response) => {
          getReviews();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const disLikeReview = (reviewId) => {
    if (accessToken == null) {
      handleShow();
    } else {
      API.post(
        ApiEndPoint.likeReview + reviewId + "/upvote",
        {
          dislike: true,
        },
        config
      )
        .then((response) => {
          getReviews();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const reviewReply = (reviewId) => {
    if (accessToken == null) {
      handleShow();
    } else {
      API.post(
        ApiEndPoint.likeReview + reviewId + "/upvote",
        {
          comment: reviewComment,
        },
        config
      )
        .then((response) => {
          setReviewComment("");

          getComments(reviewId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getOffers = () => {
    axios
      .get(`https://api2.kidzapp.com/api/3.0/other_offers/${activityId}`)
      .then((response) => {
        setOtherOffers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMissingInfo = () => {
    API.post(
      ApiEndPoint.relatedExp + "/" + activityId + "/suggestions/",
      {
        event_stoped: eventStopped,
        not_families: notfamilies,
        website_not_working: siteNotWorking,
        wrong_date_time: wrongDateTime,
        wrong_age_range: wrongAge,
        wrong_address: false,
        missing_information: false,
        notes: missingInfoNotes,
      },
      config
    )
      .then((response) => {
        getReviews();
        alert("Submitted Succcessfully !!");
        setEventStopped(false);
        setNotFamilies(false);
        setSiteNotWorking(false);
        setWrongDateTime(false);
        setWrongAge(false);
        setMissingInfoNotes(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getComments = (reviewId) => {
    API.get(ApiEndPoint.likeReview + reviewId + "/upvote")
      .then((response) => {
        setReviewReplies(response.data);
        setReviewComment("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const distance = () => {
    let lat = localStorage.getItem("lat");
    let long = localStorage.getItem("long");

    var lat1 = lat;
    var lat2 = latitude;
    var lon1 = long;
    var lon2 = longitude;

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = (lon1 * Math.PI) / 180;
    lon2 = (lon2 * Math.PI) / 180;
    lat1 = (lat1 * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    var numb = c * r;
    numb = numb.toFixed(2);
    setDistanceLoc(numb);
    return numb;
  };

  const postReview = () => {
    if (accessToken == null) {
      // alert("Please login first")
      handleClose3();
      handleShow();
    } else {
      var formdata = new FormData();
      formdata.append("web_images_", reviewFile);
      formdata.append("rating", reviewRating);
      formdata.append("title", reviewTitle);
      formdata.append("text", reviewDes);
      formdata.append("show_name", "1");

      API.post(
        ApiEndPoint.relatedExp + "/" + activityId + "/reviews/",
        formdata,
        config
      )
        .then((response) => {
          handleClose3();
          getReviews();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const responseFacebook = (response) => {
    loginWithfacebook(response);
  };

  // const appleResponse = (response) => {
  //   console.log("fb response", response)

  // };

  const loginWithfacebook = async (data) => {
    var language = localStorage.getItem("lang");

    const config = {
      headers: {
        "Accept-Language": language,
      },
    };
    await API.post(
      ApiEndPoint.getUserProfile,
      {
        facebook_access_token: data.accessToken,
      },
      config
    )
      .then((response) => {
        localStorage.setItem("userEmail", response.data.user.email);
        localStorage.setItem("userAccessToken", response.data.token);
        localStorage.setItem("loggedIn", true);
        window.location.href = "/myaccount";
      })
      .catch((error) => {
        console.log("error userpofile", error);
      });
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <MetaTags
        title={activityData?.meta_title}
        description={activityData?.meta_description}
        canonical={currentLocation}
        ogTitle={activityData?.meta_title}
        ogUrl={currentLocation}
        keywords={activityData?.meta_keywords}
        ogImage={activityData?.image_url}
      />
      <div className="about-wrapper spacing-top experience-detail-wrapper">
        <Row className="detail-hover-wrap px-md-0 position-relative">
          {activityData ? (
            <>
              <Col sm={12} className="bg-white py-3 ps-3 d-block d-md-none">
                <div
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                  className="go-back d-flex align-items-center"
                >
                  <img src={arrowLeft} alt="" />
                  <span className="text-grey">Go Back</span>
                </div>
              </Col>
              <div className="d-none d-md-block about-wrap-images container position-relative">
                {activityData.image_carousel_list?.length === 1 ? (
                  <>
                    <Col
                      className="each-detail-media single-media mx-auto p-0"
                      md={12}
                    >
                      <div className="hover-img">
                        <img
                          src={activityData?.image_url}
                          alt={activityData?.image_url_alt}
                        />
                      </div>
                    </Col>
                  </>
                ) : activityData.image_carousel_list?.length === 2 ? (
                  <>
                    {/* <Row> */}
                    {activityData?.image_carousel_alt_list.map(
                      (item, index) => (
                        <Col
                          className="each-detail-submedia"
                          md={6}
                          key={index}
                        >
                          <div className="hover-img">
                            <LazyLoadImage
                              src={item.image_url}
                              alt={item.image_alt}
                            />
                          </div>
                        </Col>
                      )
                    )}
                    {/* </Row> */}
                  </>
                ) : activityData.image_carousel_list?.length === 3 ? (
                  <>
                    <div className="image-grid grid-three-plus">
                      {activityData?.image_carousel_alt_list.map(
                        (item, index) => (
                          <LazyLoadImage
                            className="image-grid-col-2 image-grid-row-2"
                            src={item.image_url}
                            alt={item.image_alt}
                          />
                        )
                      )}
                    </div>
                  </>
                ) : activityData.image_carousel_list?.length > 3 ? (
                  <>
                    <div className="image-grid grid-three-plus">
                      {activityData?.image_carousel_alt_list
                        .slice(1, 4)
                        .map((item, index) => (
                          <>
                            <LazyLoadImage
                              className="image-grid-col-2 image-grid-row-2"
                              src={item.image_url}
                              alt={item?.image_alt}
                            />
                          </>
                        ))}
                      {/* <Button
                        onClick={handleShoww}
                        className="lightbox-btn"
                      >
                        Show all
                      </Button> */}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="d-md-none">
                <Slider {...settings}>
                  {activityData?.image_carousel_alt_list.map((item, index) => (
                    <div className="" key={index}>
                      <div className="hover-img">
                        <LazyLoadImage
                          src={item?.image_url}
                          alt={item?.image_alt}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="details-image-buttons">
                <div className="d-flex align-items-center justify-content-end gap-2 container mx-auto">
                  <button
                    onClick={() => {
                      setShow3(true);
                      setReviewFile("");
                    }}
                    className={`lightbox-rating-btn ${
                      activityData?.average_rating.toFixed(1) > 0
                        ? "pe-none"
                        : ""
                    }`}
                  >
                    {activityData?.average_rating.toFixed(1) > 0 ? (
                      <>
                        <LazyLoadImage src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg" />
                        <span>{activityData?.average_rating.toFixed(1)}</span>
                      </>
                    ) : (
                      <>
                        <LazyLoadImage src={starGrey} />
                        Be the first to review
                      </>
                    )}
                  </button>
                </div>
              </div>
              {activityData.image_carousel_list?.length > 3 ? (
                <Button
                  onClick={handleShoww}
                  className={`lightbox-btn d-md-block show-all-btn ${
                    activityData.image_carousel_list?.length > 3 ? "" : "d-none"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    style={{
                      display: "block",
                      height: "16px",
                      width: "16px",
                      fill: "currentcolor",
                    }}
                    aria-hidden="true"
                    role="presentation"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 11.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-10-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-10-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
                    ></path>
                  </svg>{" "}
                  Show all photos
                </Button>
              ) : null}
            </>
          ) : (
            <></>
          )}
          <Modal
            centered
            className="lightbox-modal"
            show={show4}
            onHide={handleClosee}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div
                id="demo"
                className="carousel lightbox-content slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide-to="0"
                    className="active"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide-to="1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide-to="2"
                  ></button>
                </div>
                <div className="carousel-inner">
                  {activityData ? (
                    <>
                      {activityData?.image_carousel_alt_list.map(
                        (item, index) => (
                          <div
                            className={
                              index == 0
                                ? "carousel-item active"
                                : "carousel-item"
                            }
                          >
                            <div className="lightbox-media">
                              <>
                                <LazyLoadImage
                                  className="image-grid-col-2 image-grid-row-2"
                                  src={item.image_url}
                                  alt="architecture"
                                />
                              </>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="prev"
                >
                  <span className="carousel-control-prev-icon"></span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="next"
                >
                  <span className="carousel-control-next-icon"></span>
                </button>
              </div>
            </Modal.Body>
          </Modal>
          {/* </>} */}
        </Row>

        <Container>
          <Row>
            {activityData ? (
              <>
                <Col sm={12}>
                  <div className="experience-detail-title-wrapper">
                    <div className="fun-sec">
                      <div className="title-wrap">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <h1 className="booking-title mb-0">
                            {activityData?.title}
                          </h1>

                          <div className="book-now-wrap mt-0 share-like-button">
                            <ul className="d-flex align-items-center gap-3">
                              <li className="link-item dropdown">
                                <Button
                                  className="nav-link"
                                  onClick={() => {
                                    markFavourite();
                                  }}
                                  to="#"
                                >
                                  {favourite ? (
                                    <LazyLoadImage
                                      src={favouriteIcon}
                                      alt="fav icon"
                                    />
                                  ) : (
                                    <LazyLoadImage
                                      src={favouriteIcon}
                                      alt="fav icon"
                                    />
                                  )}
                                </Button>
                              </li>
                              <li className="link-item">
                                <RWebShare
                                  data={{
                                    text: "Check out this content!",
                                    url: window.location.href,
                                    title: "Shared Content",
                                  }}
                                >
                                  <Button className="nav-link" to="#">
                                    <LazyLoadImage
                                      src={uploadIcon}
                                      alt="fav icon"
                                    />
                                  </Button>
                                </RWebShare>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <ul className="fun-list nav">
                          <li>
                            <LazyLoadImage src={profile2User} alt="mobile" />
                            Ages :{" "}
                            {activityData?.ages_display?.length
                              ? activityData?.ages_display?.[0] +
                                "-" +
                                activityData?.ages_display?.[
                                  activityData.ages_display?.length - 1
                                ]
                              : ""}
                          </li>
                          <li className="d-flex align-items-center">
                            {" "}
                            <LazyLoadImage src={clock} alt="mobile" />{" "}
                            <span>{activityData?.working_hours_brief}</span>
                          </li>
                          {userLat ? (
                            <>
                              <li className="d-none d-md-block">
                                {" "}
                                <LazyLoadImage
                                  src={distanceIcon}
                                  alt="mobile"
                                />
                                {distanceLoc} KM
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                        <div className="book-now-wrap mt-2">
                          <ul className="sharing-option d-flex align-items-center justify-content-between">
                            <li className="location-details d-flex align-items-center justify-content-between">
                              <div className="map-details d-flex align-items-center gap-2 w-100">
                                <LazyLoadImage
                                  className="icon-left"
                                  width="204px"
                                  height="20px"
                                  alt="website logo"
                                  src={Location}
                                />
                                <div className="location-content">
                                  <h4>
                                    {(
                                      activityData?.name +
                                      " " +
                                      activityData?.area?.name
                                    )?.length > 40
                                      ? (
                                          activityData?.name +
                                          " " +
                                          activityData?.area?.name
                                        )
                                          .substring(0, 42)
                                          .concat("...")
                                      : activityData?.name +
                                        " " +
                                        (activityData?.area?.name
                                          ? activityData?.area?.name
                                          : "")}
                                  </h4>
                                  <a className="view-map" href="#goToLocation">
                                    View Map
                                  </a>
                                </div>
                                <div className="position-relative location-header-dropdown ms-auto">
                                  <NavDropdown
                                    title={
                                      <div className="dropdown-placeholder">
                                        Select branch
                                      </div>
                                    }
                                    id="basic-nav-dropdown"
                                  >
                                    {linkedVenues.map((item, index) => {
                                      return (
                                        <NavDropdown.Item href={"/" + item.url}>
                                          {item.brief_address}
                                        </NavDropdown.Item>
                                      );
                                    })}
                                  </NavDropdown>

                                  <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                                </div>
                              </div>
                            </li>

                            <li className="header-book-now-btn">
                              <Link
                                to={
                                  activityData?.backend_booking
                                    ? `/bookings/${activityData?.id}`
                                    : activityData?.booking_url
                                }
                                state={{ from: activityData }}
                              >
                                Book Now
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* {indicators?.length > 0 ? (
                      <Indicators
                        indicators={indicators}
                        className="d-md-none px-0 mb-0 pb-0"
                      />
                    ) : null} */}
                  </div>
                </Col>
                <Col sm={12} md={7} lg={8}>
                  <Overview overview={activityData} className="overview-sec" />

                  {indicators?.length > 0 ? (
                    <div>
                      <Indicators indicators={indicators} />
                    </div>
                  ) : null}

                  <Advertisement advertisement={activityData} />
                  {/*
                    <div className="booking-page-content booking-page-detail-content">
                        <div className="review-section double-border-section">
                            <button type="button" className="btn review-modal-btn btn-primary d-flex align-items-center justify-content-between" data-bs-toggle="modal" data-bs-target="#howtobook">
                                <h2>How to book</h2> <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
                            </button>
                          </div> 
                        </div> 
                  */}
                  <Guarantee guarantee={activityData} />

                  {activityData?.price_header ? (
                    <>
                      <div className="cashback-section d-flex align-items-center mx-3 mx-md-0">
                        <div className="cashback-icon">
                          <LazyLoadImage
                            src={walletWhite}
                            width="64"
                            height="64"
                            alt=""
                          />
                        </div>
                        <div className="cashback-text">
                          <div className="cashback-title">
                            Get{" "}
                            {activityData?.price_header.reduce(function (
                              prevMax,
                              currentObj
                            ) {
                              return currentObj.cashback_highest > prevMax
                                ? currentObj.cashback_highest
                                : prevMax;
                            },
                            Number.MIN_SAFE_INTEGER)}{" "}
                            {activityData?.city?.country?.currency_code} cashback,
                          </div>
                          <p>When you book through our mobile app!</p>
                        </div>
                        <button className="get-app-btn d-md-none">
                          Use App
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="d-md-none responsive-offer px-3">
                    <h2 className="review-title mb-3">
                      <span>{`${activityData.title}`} </span> Prices
                    </h2>
                    <div
                      // className={isReached  ? 'scrolled booking-sidebar bottom-scroll' : 'scrolled booking-sidebar'}
                      className={`${
                        scroll ? "scrolled booking-sidebar" : "booking-sidebar"
                      } `}
                    >
                      <div className="offer-wraper">
                        {priceHeader ? (
                          <>
                            {priceHeader.map((item, index) => {
                              return (
                                <div className="experience-package">
                                  <div className="package-header">
                                    <h2 className="package-title">
                                      {item.header}
                                    </h2>
                                    <p>{item?.description}</p>
                                  </div>
                                  <div className="package-content">
                                    {item.values.map((itemm, index) => {
                                      return (
                                        <div>
                                          <div className="package-info">
                                            <div className="package-name">
                                              <h6>{itemm.type}</h6>
                                              <p>{itemm.small_text_type}</p>
                                            </div>
                                            <div className="package-amount">
                                              <p className="cashback-offer">
                                                <LazyLoadImage
                                                  src={walletIconSmall}
                                                  alt="pwalletackage icon"
                                                />{" "}
                                                Cashback{" "}
                                                <strong>
                                                  {itemm.orginal_price -
                                                    itemm.final_price >
                                                  0
                                                    ? formatCurrency(
                                                        itemm.orginal_price -
                                                          itemm.final_price,
                                                        itemm.currency
                                                      )
                                                    : 0}{" "}
                                                </strong>
                                              </p>
                                              {itemm.orginal_price ===
                                              itemm.final_price ? (
                                                <>
                                                  <p className="mb-0 text-end">
                                                    <span>
                                                      {itemm.currency}{" "}
                                                      {itemm.final_price}
                                                    </span>
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <p className="mb-0 prices text-end">
                                                    <del>
                                                      {itemm.currency}{" "}
                                                      {itemm.orginal_price}
                                                    </del>
                                                    <span>
                                                      {itemm.currency}{" "}
                                                      {itemm.final_price}
                                                    </span>
                                                  </p>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    <div className="package-includes">
                                      {item.show_inclusion_and_exclusion_btn ? (
                                        <>
                                          <button
                                            className="btn btn-secondary flex-grow-1"
                                            type="button"
                                            onClick={() => {
                                              getInclusionExclusion(
                                                item?.option_group_id
                                              );
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#footerSchedule"
                                          >
                                            View Inclusions & Exclusions
                                          </button>
                                        </>
                                      ) : (
                                        <div></div>
                                      )}
                                      <Link
                                        onClick={() => {
                                          localStorage.removeItem("datePicked");
                                          localStorage.removeItem("bookings");
                                        }}
                                        to={
                                          activityData.backend_booking
                                            ? `/bookings/${activityId}`
                                            : activityData.booking_url
                                        }
                                        className="book-btn btn-primary"
                                      >
                                        Book Now
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  {otherOffers?.length > 0 ? (
                    <>
                      <div className="booking-page-content booking-page-detail-content other-offers-sec">
                        <div className="other-offers">
                          <h2 className="review-title">Other Offers</h2>
                          <ul className="offer-text-wrap d-flex align-items-center gap-1">
                            {otherOffers.map((item, index) => {
                              return (
                                <>
                                  <li className="offer-text d-flex align-items-center position-relative">
                                    <LazyLoadImage src={offerIcon} />
                                    <span>{item?.title}</span>
                                    <LazyLoadImage
                                      className="offer-bg-icon d-md-none"
                                      src={offerBgIcon}
                                    />
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div
                    className="booking-page-content booking-page-detail-content location-sec"
                    id="goToLocation"
                  >
                    <div className="other-offers ">
                      <h2 className="review-title mb-3">
                        <span>{activityData?.title} </span> Location
                      </h2>
                      <div className="p-2 rounded-3 border">
                        <div
                          className="user-map"
                          style={{ height: "280px", width: "100" }}
                        >
                          <GoogleMapReact
                            key={parseFloat(latitude)}
                            bootstrapURLKeys={{
                              key: "AIzaSyBHxrllImC2OFUSQs5biR8aeR4SZOkRfJQ",
                            }} // My Google API is stored in the .env file in front-end
                            defaultCenter={{
                              lat: parseFloat(latitude),
                              lng: parseFloat(longitude),
                            }}
                            defaultZoom={10}
                            yesIWantToUseGoogleMapApiInternals={true}
                            onDragEnd={(e) => {
                              console.log("dragged", e);
                            }}
                            onGoogleApiLoaded={({ map, maps }) => {
                              renderMarker(map, maps);
                            }}
                          ></GoogleMapReact>
                        </div>
                      </div>
                    </div>
                  </div>

                  {activityData?.terms_and_conditions ? (
                    <>
                      <TermsAndConditions
                        activityData={activityData}
                        className="terms-condition-sec"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <MissingInformation
                    activityData={activityData}
                    className="missing-info-sec"
                  />
                  <div className="booking-page-content booking-page-detail-content review-section-content">
                    <div className="review-section ">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="review-title">
                          <span>{activityData?.title} </span> Rating and Reviews
                        </h2>
                        {activityData?.average_rating > 0 ? (
                          <Button
                            className="book-btn mt-3 d-none d-md-block"
                            onClick={() => {
                              setShow3(true);
                              setReviewFile("");
                            }}
                          >
                            Write a Review
                          </Button>
                        ) : null}
                      </div>

                      {activityData?.average_rating > 0 ? (
                        <>
                          <div className="row align-items-center mt-3 px-3 px-md-0">
                            <div className="col-3 col-md-6 pe-0">
                              <div className="rate-ss">
                                <span className="d-flex align-items-center">
                                  <LazyLoadImage
                                    className="img-fluid lazyload lazy d-none d-md-block"
                                    src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/Star-large.svg"
                                    alt="mobile"
                                  />{" "}
                                  {activityData?.average_rating.toFixed(1)}
                                </span>
                                <p className="mb-0">
                                  {`${activityData.number_of_reviews} Reviews`}
                                </p>
                              </div>
                            </div>
                            <div className="col-9 col-md-6">
                              <ul className="nav rating-nav">
                                <li>
                                  <div className="star-w">
                                    {[1, 2, 3, 4, 5].map((i) => (
                                      <LazyLoadImage
                                        key={i}
                                        className="img-fluid lazyload lazy"
                                        src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                        alt="mobile"
                                      />
                                    ))}
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow={
                                        activityData?.number_of_ratings_5
                                      }
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width: `${
                                          (activityData?.number_of_ratings_5 /
                                            activityData?.number_of_reviews) *
                                          100
                                        }%`,
                                      }}
                                      aria-label="review bar"
                                    ></div>
                                  </div>
                                </li>
                                <li>
                                  <div className="star-w">
                                    {[1, 2, 3, 4].map((i) => (
                                      <LazyLoadImage
                                        key={i}
                                        className="img-fluid lazyload lazy"
                                        src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                        alt="mobile"
                                      />
                                    ))}
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-va
                                      luenow={activityData.number_of_ratings_4}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width: `${
                                          (activityData?.number_of_ratings_4 /
                                            activityData?.number_of_reviews) *
                                          100
                                        }%`,
                                      }}
                                      aria-label="review bar"
                                    ></div>
                                  </div>
                                </li>
                                <li>
                                  <div className="star-w">
                                    {[1, 2, 3].map((i) => (
                                      <LazyLoadImage
                                        key={i}
                                        className="img-fluid lazyload lazy"
                                        src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                        alt="mobile"
                                      />
                                    ))}
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow={
                                        activityData.number_of_ratings_3
                                      }
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width: `${
                                          (activityData?.number_of_ratings_3 /
                                            activityData?.number_of_reviews) *
                                          100
                                        }%`,
                                      }}
                                      aria-label="review bar"
                                    ></div>
                                  </div>
                                </li>
                                <li>
                                  <div className="star-w">
                                    <LazyLoadImage
                                      className="img-fluid lazyload lazy"
                                      src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                      alt="mobile"
                                    />
                                    <LazyLoadImage
                                      className="img-fluid lazyload lazy"
                                      src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                      alt="mobile"
                                    />
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow={
                                        activityData.number_of_ratings_2
                                      }
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width: `${
                                          (activityData?.number_of_ratings_2 /
                                            activityData?.number_of_reviews) *
                                          100
                                        }%`,
                                      }}
                                      aria-label="review bar"
                                    ></div>
                                  </div>
                                </li>
                                <li>
                                  <div className="star-w">
                                    <LazyLoadImage
                                      className="img-fluid lazyload lazy"
                                      src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                      alt="mobile"
                                    />
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow={
                                        activityData.number_of_ratings_1
                                      }
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width: `${
                                          (activityData?.number_of_ratings_1 /
                                            activityData?.number_of_reviews) *
                                          100
                                        }%`,
                                      }}
                                      aria-label="review bar"
                                    ></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="review-wrapper mt-3 d-none d-md-block">
                              {reviews?.length > 0 ? (
                                <>
                                  <div className="review-list">
                                    {reviews.map((item, index) => {
                                      return (
                                        <div className="review-card">
                                          <div
                                            type="button"
                                            className="review-popup"
                                            onClick={() => {
                                              handleReviewShow(item);
                                            }}
                                          >
                                            <div className="review-header flex-wrap d-flex">
                                              <div className="review-by">
                                                <div className="review-user-media">
                                                  <LazyLoadImage
                                                    className="user-avatar"
                                                    src={
                                                      item.user
                                                        ? item.user
                                                            .profile_picture_url
                                                            ?.length > 0
                                                          ? item.user
                                                              .profile_picture_url
                                                          : "https://s3.me-south-1.amazonaws.com/testimagesbah.kidzapp.com/profilepics/1692599326961.png"
                                                        : "https://s3.me-south-1.amazonaws.com/testimagesbah.kidzapp.com/profilepics/1692599326961.png"
                                                    }
                                                    alt=""
                                                  />{" "}
                                                  <div>
                                                    <h3 className="user-name">
                                                      {item.user?.first_name +
                                                        " " +
                                                        item.user?.last_name}
                                                    </h3>
                                                    <div>
                                                      {item.rating == 1 ? (
                                                        <>
                                                          <div className="rating-star">
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />

                                                            <span className="rating">
                                                              {item.rating}.0
                                                            </span>
                                                          </div>
                                                        </>
                                                      ) : item.rating == 2 ? (
                                                        <>
                                                          <div className="rating-star">
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <span className="rating">
                                                              {item.rating}.0
                                                            </span>
                                                          </div>
                                                        </>
                                                      ) : item.rating == 3 ? (
                                                        <>
                                                          <div className="rating-star">
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <span className="rating">
                                                              {item.rating}.0
                                                            </span>
                                                          </div>
                                                        </>
                                                      ) : item.rating == 4 ? (
                                                        <>
                                                          <div className="rating-star">
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <span className="rating">
                                                              {item.rating}.0
                                                            </span>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div className="rating-star">
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <LazyLoadImage
                                                              className="img-fluid"
                                                              src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                              alt="mobile"
                                                            />
                                                            <span className="rating">
                                                              {item.rating}.0
                                                            </span>
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="review-post-date">
                                                  <p>
                                                    {moment
                                                      .unix(item.date_added)
                                                      .fromNow()}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="w-100 review-info">
                                                <div className="review-heading">
                                                  {item?.title}
                                                </div>
                                                <p className="review-text">
                                                  {item.review}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <ul className="review-counts">
                                            <li>
                                              <div
                                                className="like-count common-count"
                                                onClick={() => {
                                                  likeReview(item.id);
                                                }}
                                              >
                                                <LazyLoadImage
                                                  src={thumbsUp}
                                                  alt="Likes"
                                                />
                                                <span>{item.likes_count}</span>
                                              </div>
                                            </li>
                                            <li>
                                              <div
                                                className="like-count common-count"
                                                onClick={() => {
                                                  disLikeReview(item.id);
                                                }}
                                              >
                                                <LazyLoadImage
                                                  src={thumbsDown}
                                                  alt="Dislikes"
                                                />
                                                <span>
                                                  {item.dislikes_count}
                                                </span>
                                              </div>
                                            </li>
                                            <li>
                                              <div
                                                className="like-count common-count"
                                                onClick={() => {
                                                  getComments(item.id);
                                                  setSelectedIndex(index);
                                                }}
                                              >
                                                <a
                                                  className="btn"
                                                  data-bs-toggle="collapse"
                                                  href="#collapseOne"
                                                  onClick={() => {
                                                    setSelectedIndex(index);
                                                  }}
                                                >
                                                  <LazyLoadImage
                                                    src={replyIcon}
                                                    alt="reply"
                                                  />{" "}
                                                  <span>reply</span>
                                                </a>
                                              </div>
                                            </li>
                                            <li>
                                              <div
                                                className="like-count common-count"
                                                onClick={() => {
                                                  getComments(item.id);
                                                  setSelectedIndexReplies(
                                                    index
                                                  );
                                                }}
                                              >
                                                <a
                                                  className="btn"
                                                  data-bs-toggle="collapse"
                                                  href="#collapseReply"
                                                >
                                                  <LazyLoadImage
                                                    src={chevronDown}
                                                    alt="reply"
                                                  />{" "}
                                                  <span>View All Replies</span>
                                                </a>
                                              </div>
                                            </li>
                                          </ul>
                                          {reviewReplies?.length > 0 &&
                                          selectedIndexReplies == index ? (
                                            <>
                                              <div className="replies">
                                                {reviewReplies.map(
                                                  (item, index) => {
                                                    return (
                                                      <div className="review-collapse ms-3">
                                                        <div className="replies-thread">
                                                          <div className="review-header flex-wrap d-flex gap-2 align-items-center">
                                                            <div className="review-avtar">
                                                              <LazyLoadImage
                                                                className="img-fluid lazyload lazy"
                                                                src={
                                                                  item.user
                                                                    ? item.user
                                                                        .profile_picture_url
                                                                        ?.length >
                                                                      0
                                                                      ? item
                                                                          .user
                                                                          .profile_picture_url
                                                                      : "https://s3.me-south-1.amazonaws.com/testimagesbah.kidzapp.com/profilepics/1692599326961.png"
                                                                    : "https://s3.me-south-1.amazonaws.com/testimagesbah.kidzapp.com/profilepics/1692599326961.png"
                                                                }
                                                                alt="mobile"
                                                              />{" "}
                                                              4.0
                                                            </div>
                                                            <div className="review-by">
                                                              <h2 className="review-heading">
                                                                {
                                                                  item.user
                                                                    ?.first_name
                                                                }{" "}
                                                                {
                                                                  item.user
                                                                    ?.last_name
                                                                }
                                                              </h2>
                                                              <p className="time">
                                                                2 days ago
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <p className="review-reply-text">
                                                            {item?.comment}
                                                          </p>
                                                          <ul className="review-counts">
                                                            <li>
                                                              <div className="like-count common-count">
                                                                <LazyLoadImage
                                                                  src={thumbsUp}
                                                                  alt="Likes"
                                                                />
                                                                <span>0</span>
                                                              </div>
                                                            </li>
                                                            <li>
                                                              <div className="like-count common-count">
                                                                <LazyLoadImage
                                                                  src={
                                                                    thumbsDown
                                                                  }
                                                                  alt="Dislikes"
                                                                />
                                                                <span>0</span>
                                                              </div>
                                                            </li>
                                                            {/* <li>
                                                            <div
                                                              className="like-count common-count"

                                                            >
                                                              <a
                                                                className="btn"
                                                                data-bs-toggle="collapse"
                                                                href="#collapseOne"

                                                              >
                                                                <LazyLoadImage src={replyIcon} alt="reply" />{" "}
                                                                <span>reply</span>
                                                              </a>
                                                            </div>
                                                          </li> */}
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {selctedIndex == index ? (
                                            <>
                                              <div key={selctedIndex}>
                                                <div className="review-collapse">
                                                  <div className="form-group">
                                                    <textarea
                                                      className="form-control"
                                                      value={reviewComment}
                                                      onChange={(e) => {
                                                        setReviewComment(
                                                          e.target.value
                                                        );
                                                      }}
                                                    ></textarea>
                                                    <Button
                                                      className="review-btn"
                                                      onClick={() => {
                                                        reviewReply(item.id);
                                                      }}
                                                    >
                                                      Post
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {selectedIndexReplies == index ? (
                                            <>
                                              <div className="collapse">
                                                <div className="review-collapse">
                                                  <div className="replies-thread">
                                                    <div className="review-header flex-wrap d-flex">
                                                      <div className="review-avtar">
                                                        <LazyLoadImage
                                                          className="img-fluid lazyload lazy"
                                                          src="http://graph.facebook.com/10160291557445105/picture?width=256&amp;height=256"
                                                          alt="mobile"
                                                        />{" "}
                                                        4.0
                                                      </div>
                                                      <div className="review-by">
                                                        <div className="rating-star">
                                                          <LazyLoadImage
                                                            className="img-fluid"
                                                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                            alt="mobile"
                                                          />
                                                          <LazyLoadImage
                                                            className="img-fluid"
                                                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                            alt="mobile"
                                                          />
                                                          <LazyLoadImage
                                                            className="img-fluid"
                                                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                            alt="mobile"
                                                          />
                                                          <LazyLoadImage
                                                            className="img-fluid"
                                                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                            alt="mobile"
                                                          />
                                                          <span>4.0</span>
                                                        </div>
                                                        <div className="review-post-date">
                                                          <p>4 years ago</p>
                                                        </div>
                                                      </div>
                                                      <div className="w-100 review-info">
                                                        <h2 className="review-heading">
                                                          New Experience{" "}
                                                        </h2>
                                                        <p className="review-text">
                                                          The kids had fun and
                                                          got to try something
                                                          new. There are several
                                                          activities to try and
                                                          some are challenging,
                                                          which is great. Best
                                                          of all, let's their
                                                          energy out!
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            {reviews?.length > 0 ? (
                              <>
                                <div className="review-wrapper review-carousel-card mt-3 d-block d-md-none px-3">
                                  <div className="review-list">
                                    <Slider
                                      className="owl-theme  review-carousel "
                                      {...optionsPosterSlider}
                                    >
                                      {reviews.map((item, index) => {
                                        return (
                                          <div className="review-card">
                                            <div
                                              type="button"
                                              className="review-popup"
                                              onClick={() => {
                                                handleReviewShow(item);
                                              }}
                                            >
                                              <div className="review-header flex-wrap d-flex">
                                                <div className="review-by">
                                                  <div className="review-user-media">
                                                    <LazyLoadImage
                                                      className="user-avatar"
                                                      src={
                                                        item.user
                                                          ?.profile_picture_url
                                                      }
                                                      alt=""
                                                    />{" "}
                                                    <div>
                                                      <h3 className="user-name">
                                                        {item.user?.first_name +
                                                          " " +
                                                          item.user?.last_name}
                                                      </h3>
                                                      <div>
                                                        {item.rating == 1 ? (
                                                          <>
                                                            <div className="rating-star">
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />

                                                              <span className="rating">
                                                                {item.rating}.0
                                                              </span>
                                                            </div>
                                                          </>
                                                        ) : item.rating == 2 ? (
                                                          <>
                                                            <div className="rating-star">
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <span className="rating">
                                                                {item.rating}.0
                                                              </span>
                                                            </div>
                                                          </>
                                                        ) : item.rating == 3 ? (
                                                          <>
                                                            <div className="rating-star">
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <span className="rating">
                                                                {item.rating}.0
                                                              </span>
                                                            </div>
                                                          </>
                                                        ) : item.rating == 4 ? (
                                                          <>
                                                            <div className="rating-star">
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <span className="rating">
                                                                {item.rating}.0
                                                              </span>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="rating-star">
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <LazyLoadImage
                                                                className="img-fluid"
                                                                src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                                                                alt="mobile"
                                                              />
                                                              <span className="rating">
                                                                {item.rating}.0
                                                              </span>
                                                            </div>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="review-post-date">
                                                    <p>
                                                      {moment
                                                        .unix(item.date_added)
                                                        .fromNow()}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="w-100 review-info">
                                                  <div className="review-heading">
                                                    {item?.title}
                                                  </div>
                                                  <p className="review-text">
                                                    {item.review}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </Slider>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        //For 0 Reviews

                        <>
                          <div className="d-flex flex-column align-items-center mt-3">
                            <LazyLoadImage
                              style={{ width: 37, height: 35 }}
                              src={simpleStarIcon}
                            />
                            <Button
                              className="book-btn mt-3"
                              onClick={() => {
                                setShow3(true);
                                setReviewFile("");
                              }}
                            >
                              Be the first to review
                            </Button>
                          </div>
                        </>
                      )}
                      <div className="text-center">
                        {activityData?.average_rating > 0 ? (
                          <Button
                            className="book-btn mt-3 d-block d-md-none"
                            onClick={() => {
                              setShow3(true);
                              setReviewFile("");
                            }}
                          >
                            Write a Review
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Attractions activityId={activityId} config={config} />
                  </div>
                </Col>
                <Col
                  className={`${
                    isReached ? "no-scroll" : ""
                  } d-none d-md-block`}
                  sm={12}
                  md={5}
                  lg={4}
                >
                  <div>
                    <h2 className="review-title mb-3">
                      <span>{`${activityData.title}`} </span> Prices
                    </h2>
                    <div
                      // className={isReached  ? 'scrolled booking-sidebar bottom-scroll' : 'scrolled booking-sidebar'}
                      className={`${
                        scroll ? "scrolled booking-sidebar" : "booking-sidebar"
                      } `}
                    >
                      <div className="offer-wraper">
                        {priceHeader ? (
                          <>
                            {priceHeader.map((item, index) => {
                              return (
                                <div className="experience-package">
                                  <div className="package-header">
                                    <h2 className="package-title">
                                      {item.header}
                                    </h2>
                                    <p>{item?.description}</p>
                                  </div>
                                  <div className="package-content">
                                    {item.values.map((itemm, index) => {
                                      return (
                                        <div>
                                          <div className="package-info">
                                            <div className="package-name">
                                              <h6>{itemm.type}</h6>
                                              <p>{itemm.small_text_type}</p>
                                            </div>
                                            <div className="package-amount">
                                              <p className="cashback-offer">
                                                <LazyLoadImage
                                                  src={walletIconSmall}
                                                  alt="pwalletackage icon"
                                                />{" "}
                                                Cashback{" "}
                                                <strong>
                                                  {itemm.orginal_price -
                                                    itemm.final_price >
                                                  0
                                                    ? formatCurrency(
                                                        itemm.orginal_price -
                                                          itemm.final_price,
                                                        itemm.currency
                                                      )
                                                    : 0}{" "}
                                                </strong>
                                              </p>
                                              {itemm.orginal_price ===
                                              itemm.final_price ? (
                                                <>
                                                  <p className="mb-0">
                                                    <span>
                                                      {itemm.currency}{" "}
                                                      {itemm.final_price}
                                                    </span>
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <p className="mb-0">
                                                    <del>
                                                      {itemm.currency}{" "}
                                                      {itemm.orginal_price}
                                                    </del>
                                                    <span>
                                                      {itemm.currency}{" "}
                                                      {itemm.final_price}
                                                    </span>
                                                  </p>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    <div className="package-includes">
                                      {item.show_inclusion_and_exclusion_btn ? (
                                        <>
                                          <button
                                            className="btn btn-secondary mt-3 flex-grow-1"
                                            type="button"
                                            onClick={() => {
                                              getInclusionExclusion(
                                                item?.option_group_id
                                              );
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#footerSchedule"
                                          >
                                            View Inclusions & Exclusions
                                          </button>
                                        </>
                                      ) : (
                                        <div></div>
                                      )}
                                      <Link
                                        onClick={() => {
                                          localStorage.removeItem("datePicked");
                                          localStorage.removeItem("bookings");
                                        }}
                                        to={
                                          activityData.backend_booking
                                            ? `/bookings/${activityId}`
                                            : activityData.booking_url
                                        }
                                        className="book-btn btn-primary"
                                      >
                                        Book Now
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Loader />
              </>
            )}
          </Row>
        </Container>
      </div>
      {/* Schedule Modal */}
      <div className="modal modal-blue detail-modal" id="viewSchedule">
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">Working Hours</div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modal-schedule-content">
                <ul>
                  {workingHr.map((item, index) => {
                    return (
                      <li>
                        {item.day}
                        <span>
                          {item.open}-{item.close}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Website Modal */}
      <div className="modal modal-blue detail-modal" id="addCalender">
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title"></h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modal-calender-content">
                <h4 className="calender-title text-center">Add to Calendar</h4>
                <p className="cal-subtitle text-center">
                  {activityData ? activityData?.title : ""}
                </p>
                <p className="View-schedule-text">View Schedule</p>
                <div className="form-group mb-3">
                  <label className="text-center">
                    Select Desired Date and Time
                  </label>
                  {dates?.length > 0 ? (
                    <>
                      <select name="add-to-cal-time" className="form-control">
                        {dates.map((item, index) => {
                          return (
                            <option value={item}>{formatDate(item)}</option>
                          );
                        })}
                      </select>
                    </>
                  ) : (
                    <></>
                  )}

                  {times?.length > 0 ? (
                    <>
                      <select name="add-to-cal-time" className="form-control">
                        {times.map((item, index) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </select>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="form-group mb-3">
                  <select name="reminder-cal" className="form-control">
                    <option value="15">15 Minutes before</option>
                    <option value="30">30 Minutes before</option>
                    <option value="45">45 Minutes before</option>
                    <option value="60">1 Hour before</option>
                  </select>
                </div>
                <div className="add-to-calendar-btn-wrap">
                  <div
                    className="add-to-calendar d-flex flex-column align-items-center text-center"
                    id="add-to-calendar"
                  >
                    <label
                      className="add-to-calendar-checkbox"
                      onClick={() => {
                        setToggleCalendar(!toggleCalendar);
                      }}
                    >
                      Add to Calendar
                    </label>
                    {activityData && toggleCalendar ? (
                      <>
                        <a
                          className="icon-google show"
                          rel="nofollow"
                          target="_blank"
                          href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${
                            activityData?.title
                          }&details=${activityData?.description.replace(
                            /\n/g,
                            "%0A"
                          )}`}
                          id="googleCal"
                        >
                          Google Calender
                        </a>
                        <a
                          className="icon-outlook show"
                          rel="nofollow"
                          href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR
                                                 VERSION:2.0
                                                 BEGIN:VEVENT
                                                 SUMMARY:${activityData?.title}
                                                 DESCRIPTION:${activityData?.description.replace(
                                                   /\n/g,
                                                   "%0A"
                                                 )}
                                                 DTSTART:20230905T120000Z
                                                 DTEND:20230905T140000Z
                                                 END:VEVENT
                                                 END:VCALENDAR`}
                        >
                          Default Calender
                        </a>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* howtobook */}
      <div className="modal detail-modal detail-modal-white" id="howtobook">
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">How to book</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            {activityData ? (
              <>
                <div className="modal-body">
                  <div className="row inclusions" id="inclusions">
                    <div className="col-md-6 data-inclusions">
                      <p>{activityData.how_to_book}</p>
                      <p>{activityData.know_before_you_go}</p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="modal-body">
                  <div className="row inclusions" id="inclusions">
                    <div className="col-md-6 data-inclusions">
                      <p>dummy text</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/*  Missing Info popup */}
      <div
        className="modal detail-modal missing-info-modal detail-modal-white modal-backdrop-dark"
        id="missingInfo"
      >
        <div className="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">Missing Information?</h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="missing-info-wrap col-12">
                  <div className="">
                    <div className="info-about">
                      <div>
                        <div className="form-group mb-3">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setEventStopped(e.target.checked);
                            }}
                            value="event_stoped"
                            id="check1"
                            name="option1"
                            className="form-check-input form-control"
                          />
                          <label htmlFor="check1" className="form-label">
                            The event/activity has stopped
                          </label>
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setNotFamilies(e.target.checked);
                            }}
                            value="not_families"
                            id="check2"
                            name="option2"
                            className="form-check-input form-control"
                          />
                          <label htmlFor="check2" className="form-label">
                            It's not for families
                          </label>
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setSiteNotWorking(e.target.checked);
                            }}
                            value="website_not_working"
                            id="check3"
                            name="option3"
                            className="form-check-input form-control"
                          />
                          <label className="form-label">
                            Website is not opening{" "}
                          </label>
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setWrongDateTime(e.target.checked);
                            }}
                            value="wrong_date_time"
                            id="check4"
                            name="option4"
                            className="form-check-input form-control"
                          />
                          <label className="form-label">
                            Time/Date/Day info is incorrect
                          </label>
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setWrongAge(e.target.checked);
                            }}
                            value="wrong_age_range"
                            id="check5"
                            name="option5"
                            className="form-check-input form-control"
                          />
                          <label className="form-label">
                            The age range is wrong
                          </label>
                        </div>
                        <div className="form-group mb-3">
                          <textarea
                            type="text"
                            onChange={(e) => {
                              setMissingInfoNotes(e.target.value);
                            }}
                            placeholder="Add a note (optional)"
                            className="form-control"
                          ></textarea>
                        </div>
                        <div className="submit-wrap">
                          <Button
                            data-bs-dismiss="modal"
                            onClick={() => {
                              handleMissingInfo();
                            }}
                            className="submit-info"
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //login with email /fb modal */}
      <Modal className="login-modal" show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="login-content">
            <div className="login-text text-center">
              <LazyLoadImage
                width="92px"
                height="20px"
                alt="website logo"
                src={logoLogin}
              />
              <div className="login-title">{t("login_signUp")}</div>
            </div>
            <ul className="login-button-group d-flex flex-column align-items-center">
              <li className="login-button">
                <Button variant="primary" onClick={confirmEmailShow}>
                  <LazyLoadImage src={envIcon} alt="login with Email" />
                  {t("continue_with")}{" "}
                  <strong className="ps-1">{t("email")}</strong>{" "}
                </Button>
              </li>
              <FacebookLogin
                appId="231173804067588"
                autoLoad={false}
                fields="name, email"
                callback={responseFacebook}
              />
              {/* <li className="login-button">
              <AppleLogin
                          clientId="com.kidzapp.app"
                          redirectURI="https://stage.jizoos.com/webservices/webuser/sign_in_with_apple"
                          usePopup={false}
                          scope="email name"
                          responseMode="query"
                           responseType='code'
                          callback={appleResponse}
                          render={renderProps => (  //Custom Apple Sign in Button
                            <button
                              onClick={renderProps.onClick}
                              style={{
                                backgroundColor: "white",
                                padding: 10,
                                border: "1px solid black",
                                fontFamily: "none",
                                lineHeight: "25px",
                                fontSize: "25px"
                              }}
                            >
                              <i className="fa-brands fa-apple px-2 "></i>
                              Continue with Apple
                            </button>
                          )}
                        />

                      </li> */}
            </ul>
            <p className="terms-text">
              {t("by_using_kidzapp_you_agree_to_our")}
              <NavLink to="/">{t("terms_and_conditions")}</NavLink>
              {t("and")} <NavLink to="/">{t("privacy_policy")}</NavLink>
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="email-modal" show={show2} onHide={confirmEmailHide}>
        <Modal.Body>
          <div className="email-content">
            <div className="email-title-section">
              <LazyLoadImage
                className="kids-image"
                src={kidsJump}
                alt="Enter your Email ID"
                height="150px"
                width="120px"
              />
              <h3 className="email-title">
                {t("enter_email_text")} {t("email_id")}
              </h3>
            </div>
            <Form className="text-center">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="johnsnow@gmail.com"
                />
              </Form.Group>
              <Button
                onClick={() => {
                  customEmailLogin();
                }}
                variant="primary"
              >
                {t("submit")}
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="email-modal" show={show1} onHide={confirmEmailHide}>
        <Modal.Body>
          <div className="email-content d-none">
            <div className="email-title-section">
              <LazyLoadImage
                className="kids-image"
                src={kidsJump}
                alt="Enter your Email ID"
                height="150px"
                width="120px"
              />
              <h3 className="email-title">
                {t("verify_your")} <strong>{email}</strong>
              </h3>
            </div>
            <Form className="text-center">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="email" placeholder="johnsnow@gmail.com" />
              </Form.Group>
              <Button variant="primary" type="submit">
                {t("submit")}
              </Button>
            </Form>
          </div>
          <div className="email-content text-center resend-content-mail">
            <div className="email-title-section">
              <LazyLoadImage
                className="kids-image"
                src={kidsJump}
                alt="Enter your Email ID"
                height="150px"
                width="120px"
              />
              <h3 className="email-title">
                {t("verify_your")} <strong>{t("email_id")}</strong>
              </h3>
            </div>
            <p>
              <a href={"mailto:" + email}>{email}</a>
            </p>
            <p className="text-muted">
              {t("please_open_your_email_and_confirm_your_email_address")}
            </p>
            <NavLink
              className="nav-link"
              to="https://mail.google.com/mail/u/0/#inbox"
              target="_blank"
            >
              <Button variant="primary">{t("open_your_mail")}</Button>
            </NavLink>
            {loading ? (
              <div className="bubble-loader mt-2">
                <div className="login-bubble-dots"></div>
                <div className="login-bubble-dots"></div>
                <div className="login-bubble-dots"></div>
              </div>
            ) : null}

            <p className="resend-mail">
              <a
                onClick={() => {
                  setShow1(false);
                  setShow2(true);
                }}
              >
                {t("resend_email")}
              </a>
            </p>
            <p className="text-muted text-bottom">{t("check_spam")}</p>
          </div>
        </Modal.Body>
      </Modal>
      <div
        className="modal detail-modal detail-modal-white modals inclusion-exclusion-modal"
        id="footerSchedule"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Inclusions</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row inclusions" id="inclusions">
                <div className="col-md-12 data-inclusions d-flex flex-wrap">
                  {inclusions.length > 0 ? (
                    <>
                      {inclusions.map((item, index) => {
                        return <p key={item.id}>{item.text}</p>;
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-12 mt-5">
                  {exclusions.length > 0 ? (
                    <>
                      <h2
                        className="modal-title exclusion-title"
                        id="exampleModalLabel"
                      >
                        Exclusions
                      </h2>
                      <div className="col-md-12 data-exclusions d-flex flex-wrap">
                        {exclusions.map((item, index) => {
                          return <p key={item.id}>{item.text}</p>;
                        })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reviews Modal */}
      {activityData ? (
        <>
          <Modal
            className="review-modal"
            centered
            show={showReview}
            onHide={handleReviewClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>{activityData?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="review-modal-content">
                <div className="review-header flex-wrap d-flex">
                  <div className="review-avtar">
                    <LazyLoadImage
                      alt=""
                      src={
                        currentReview.user
                          ? currentReview.user.profile_picture_url?.length > 0
                            ? currentReview.user.profile_picture_url
                            : "https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/Star-large.svg"
                          : "https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/Star-large.svg"
                      }
                    />
                  </div>
                  <div className="review-by">
                    {currentReview.rating == 1 ? (
                      <>
                        <div className="rating-star flex-wrap">
                          <div className="review-added-by w-100">
                            <p>Review Name 1</p>
                          </div>
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />

                          <span>{currentReview.rating}.0</span>
                        </div>
                      </>
                    ) : currentReview.rating == 2 ? (
                      <>
                        <div className="rating-star flex-wrap">
                          <div className="review-added-by w-100">
                            <p>Review Name 1</p>
                          </div>
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <span>{currentReview.rating}.0</span>
                        </div>
                      </>
                    ) : currentReview.rating == 3 ? (
                      <>
                        <div className="rating-star flex-wrap">
                          <div className="review-added-by w-100">
                            <p>Review Name 1</p>
                          </div>
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <span>{currentReview.rating}.0</span>
                        </div>
                      </>
                    ) : currentReview.rating == 4 ? (
                      <>
                        <div className="rating-star flex-wrap">
                          <div className="review-added-by w-100">
                            <p>Review Name 1</p>
                          </div>
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <span>{currentReview.rating}.0</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="rating-star flex-wrap">
                          <div className="review-added-by w-100">
                            <p>Review Name 1</p>
                          </div>
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <LazyLoadImage
                            className="img-fluid"
                            src="https://drfsb8fjssbd3.cloudfront.net/images/kidzapp_new/star-16.svg"
                            alt="mobile"
                          />
                          <span>{currentReview.rating}.0</span>
                        </div>
                      </>
                    )}

                    <div className="review-post-date">
                      <p>{moment.unix(currentReview.date_added).fromNow()}</p>
                    </div>
                  </div>
                  <div className="w-100 review-info">
                    <h2 className="review-heading">{currentReview?.title}</h2>
                    <p className="review-text">{currentReview.review}</p>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Review Modal */}
          <Modal
            className="write-review-modal modal-backdrop-dark"
            show={show3}
            onHide={handleClose3}
          >
            <Modal.Header closeButton>
              <Modal.Title>Write a review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="review-form">
                <div className="review-rating">
                  <div className="rating-faces-wrap">
                    <div className="rate-class mx-auto">
                      {reviewRatings.map((item) => (
                        <div className="radio-type">
                          <label className="radio-container radio-type label_item">
                            <input
                              onClick={() => {
                                setReviewRating(item.id);
                              }}
                              type="radio"
                              name="ratings[]"
                              id="ratings1"
                              className="check radio_item"
                              value="1"
                            />
                            <LazyLoadImage
                              src={
                                item?.id <= reviewRating
                                  ? "https://webbah.kidzapp.com/assets/img/star-black-border.svg"
                                  : "https://webbah.kidzapp.com/assets/img/star-black-blank-border.svg"
                              }
                              alt="rate"
                              className="lazyload lazy"
                            />
                            <span className="rate-text">{item.value}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    id="title"
                    name="title"
                    onChange={(e) => {
                      setReviewTitle(e.target.value);
                    }}
                    placeholder="title"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    type="text"
                    id="exp"
                    name="exp"
                    onChange={(e) => {
                      setReviewDes(e.target.value);
                    }}
                    placeholder="How was your experience"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Add Photo</label>
                  <div className="file-input-block">
                    <div className="text-block">
                      <p>
                        Drag &amp;amp; Drop to upload or <strong>Browse</strong>
                      </p>
                    </div>
                    <div id="file-icon-block" className="file-icon-block">
                      <span className="file-icon">
                        <LazyLoadImage
                          src="https://drfsb8fjssbd3.cloudfront.net/images/file-upload-icon-kd-new.png"
                          alt="file-upload-icon"
                          className="lazyload lazy"
                        />
                      </span>
                    </div>
                    <input
                      type="file"
                      name="images[]"
                      id="browse"
                      onChange={(e) => {
                        setReviewFile(e.target.files[0]);
                      }}
                      className="review-images add-photos"
                      multiple=""
                    />
                    <div id="preview" className="gallery">
                      <div
                        id="cate0"
                        className="gallery-image"
                        data-file="aws1.jpg"
                      >
                        {reviewFile ? (
                          <>
                            <LazyLoadImage
                              className="imageThumb"
                              src={URL.createObjectURL(reviewFile)}
                              alt="gallery-image/"
                            />
                            <span
                              className="remove-thumb"
                              onClick={() => {
                                setReviewFile("");
                              }}
                            ></span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="footer-action">
                <Button
                  variant="primary"
                  className="book-btn"
                  onClick={() => {
                    postReview();
                  }}
                >
                  Submit
                </Button>
                <div className="switch-sec">
                  <span className="switch-name">
                    Hide name
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="switch-case"
                        className="input-check"
                      />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
export default Details;
