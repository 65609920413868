import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom';

function MetaTags({ ...data }) {
  const location = useLocation()
  const title ="Kids Activities in Dubai, Abu Dhabi | UAE | Kidzapp"
  const pageMetaTags = [
    { path: "/", title: "Kids Activities in Dubai, Abu Dhabi | UAE | Kidzapp", description: "Find out everything that&#039;s Kidzappening in the UAE! Read through our regular articles and discover all kid-friendly activities.", ogTitle: "", ogImage: "", ogUrl: "", canonical: "" },
    { path: "/blogs", title: "The KZ Blog - Kidzapp | Kidzapp", description: "Find out everything that&#039;s Kidzappening in the UAE! Read through our regular articles and discover all kid-friendly activities.", ogTitle: "The KZ Blog - Kidzapp", ogImage: "https://webbah.kidzapp.com/2020/09/1/se.jpg", ogUrl: "https://kidzapp.com/blog", canonical: "https://kidzapp.com/blog" },
    { path: "/find-activities", title: "Best Things to do with your Kids in UAE | Kidzapp", description: "Find an array of Search activities for kids that are creative, easy and engaging.", ogTitle: "Best Things to do with your Kids in UAE", ogImage: "https://webbah.kidzapp.com/2020/09/1/se.jpg", ogUrl: "https://kidzapp.com/find-activities", canonical: "https://kidzapp.com/find-activities" },
    { path: "/awards", title: "The Kidzapp Awards – Winners | Kidzapp", description: "As part of our commitment to making life better for parents and enriching families, we’ve decided to launch the first Family Activities focused Awards – run by parents, chosen by parents! Here’s your chance to show some love to those businesses that have kept our families entertained, enriched, and informed, and made the UAE one &hellip", ogTitle: "The Kidzapp Awards – Winners", ogImage: "https://webbah.kidzapp.com/2020/09/1/se.jpg", ogUrl: "https://kidzapp.com/blog", canonical: "https://kidzapp.com/the-kidzapp-awards" },
    { path: "/kidzapp-tv", title: "Kidzapp TV | Kidzapp", description: "Kidzapp TV presents some useful, educational, and entertaining videos through which kids get to learn different types of activities and skills. Scroll along the page to checkout the different videos available.", ogTitle: "Kidzapp TV", ogImage: "https://webbah.kidzapp.com/2020/09/1/se.jpg", ogUrl: "https://kidzapp.com/kidzapp-tv", canonical: "https://kidzapp.com/kidzapp-tv" },
  ];

  const [currentPage, setCurrentPage] = useState({});

  useEffect(() => {
    const filterPath = pageMetaTags.filter((i) => i.path === location.pathname);
    setCurrentPage(filterPath[0]);
  }, [location.pathname]);
  return (
    <>
      <Helmet>
        <title>{data.title ? data.title : currentPage?.title||title}</title>
        <link rel="canonical" href={data?.canonical ? data?.canonical : currentPage?.canonical} />
        <meta
          name="description"
          content={data.description ? data.description : currentPage?.description}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Kidzapp" />
        <meta name="author" content="Kidzapp"/>
        <meta property="og:title" content={data.ogTitle ? data.ogTitle : currentPage?.ogTitle} />
        <meta property="og:desription" content={data.description ? data.description : currentPage?.description} />
        <meta property="og:image" content={data.ogImage ? data.ogImage : currentPage?.ogImage} />
        <meta property="og:url" content={data.ogUrl ? data.ogUrl : currentPage?.ogUrl} />
        <meta property="keywords" content={data.keywords ? data.keywords : ""} />
      </Helmet>
    </>
  )
}

export default MetaTags