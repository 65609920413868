import { LazyLoadImage } from "react-lazy-load-image-component";

function Advertisement({ advertisement, className }) {
  return (
    <>
      <div
        className={`booking-page-content booking-page-detail-content ${
          className ? className : ""
        }`}
      >
        <div className="poster-slider">
          <h4 className="poster-title">Advertisement</h4>
          <div className="poster-carousel-content hover-img">
            <LazyLoadImage
              width="585px"
              height="330px"
              alt={advertisement?.image_carousel_alt_list[0]?.image_alt}
              src={advertisement?.image_carousel_alt_list[0]?.image_url}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Advertisement;
