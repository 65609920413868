
const formatCurrency = (amount,currency)=>{
    const currencyFormatter = new Intl.NumberFormat('en-US',{
        style: 'currency',
        currency: currency?currency:'AED',
        minimumFractionDigits:0,
        maximumFractionDigits:2,
    })
    return currencyFormatter.format(amount)
};
export {formatCurrency};