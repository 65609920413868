import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function TermsAndConditions({ ...data }) {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      <div
        className={`booking-page-content booking-page-detail-content ${data.className}`}
      >
        <div
          onClick={() => toggleReadMore()}
          style={{ cursor: "pointer" }}
          className="review-section d-flex justify-content-between"
        >
          <div>
            <h2 className="review-title">
              Terms of use <span> {data?.activityData?.title} </span>
            </h2>
            <div className="review-text">
              <p
                dangerouslySetInnerHTML={{
                  __html: isReadMore
                    ? data?.activityData?.terms_and_conditions
                        .slice(0, 150)
                        .replace(/\n/g, "<br>")
                    : data?.activityData?.terms_and_conditions.replace(
                        /\n/g,
                        "<br>"
                      ),
                }}
              />
            </div>
          </div>
          <FontAwesomeIcon
            icon={`fa-solid  ${
              isReadMore ? "fa-chevron-right" : "fa-chevron-up"
            } `}
            style={{ marginTop: "4px" }}
          />
        </div>
      </div>
    </>
  );
}
export default TermsAndConditions;
