import React from 'react'
import './loader.css';

function loader() {
    return (
        <div id="preloader" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div id="status"></div>
        </div>
    )
}

export default loader