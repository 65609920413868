export const ApiEndPoint = {
  blogs: "api/3.0/blogs",
  filter: "filter/",
  handpickedExperience: "/api/3.0/experiences/curated-list/",
  listCollection: "api/3.0/lists",
  categories: "api/3.0/categories",
  subscribeLetter: "api/3.0/user/subscribe-to-newsletter/",
  reviews: "api/3.0/reviews/featured",
  customEmailLogin: "api/3.0/customlogin/",
  verifyEmail: "api/3.0/custom_email_authentication/",
  guestLogin: "api/3.0/guest_login/",
  getUserProfile: "/api/3.0/user",
  geoCode: "https://maps.googleapis.com/maps/api/geocode/json",
  logout: "api/3.0/custom_logout/",
  getAllages: "api/3.0/ages",
  updateAges: "/api/3.0/user/ages",
  getUserAges: "api/3.0/user/ages",
  getBookingbyDate: "/api/3.0/experiences",
  makebooking: "api/3.0/bookings",
  linkedVenues: "api/3.0/linked_venues",
  relatedExp: "api/3.0/experiences",
  favourite: "/api/3.0/user/wishlist",
  likeReview: "/api/3.0/review/",
  likeVideo: "/api/3.0/tv/videos/",
  wishlistVideo: "/api/3.0/tv/videos/wishlist",
  events: "/api/3.0/events",
  otherOffers: "/api/3.0/other_offers",
  getPaymenthodFatoora: "/api/3.0/bookings/fatoora-payment-methods",
  makefatooraPayment: "/api/3.0/bookings",
  getBookingbyId: "/api/3.0/bookings",
  filterExp: "/api/3.0/experiences",
  featuredList: "/api/3.0/experiences/curated-list",
  getAreas: "/api/3.0/areas",
  getCategories: "/api/3.0/categories",
  getAwardCategories: "/api/3.0/award/category/",
  getCandidatebyCategoryId: "/api/3.0/award/candidate/category/",
  createVote: "/api/3.0/award/vote/",
  kidstvVideos: "/api/3.0/tv/featured/videos",
  videoCategory: "/api/3.0/tv/video_category",
  getVideosByCategoryId: "/api/3.0/tv/videos",
  searchVideoTv: "/api/3.0/tv/videos",
  createPaymobPayment: "/api/3.0/bookings/",
  getPayMobCards: "/api/3.0/user/list-paymob-cards/",
  makeCheckoutPayment: "/api/3.0/bookings/",
  getUserCheckoutCards: "/api/3.0/user/list-checkout-cards/",
  getCities: "/api/3.0/cities",
  getSubcategories: "/api/3.0/subcategories",
  myBookings: "/api/3.0/bookings_list",
  myActivityWishList: "/api/3.0/user/wishlist",
  mytvWishList: "/api/3.0/tv/videos/wishlist",
  wallet_transactions: "/api/3.0/wallet_transactions",
  wallet_Balance: "/api/3.0/wallet_transactions/balance",
  applyBankVoucher: "/api/3.0/bank-voucher/apply-bank-voucher/",
  applyBookingPromoCode: "/api/3.0/validate/promocode",
  recommendedApi: "/api/3.0/experiences/",
};
