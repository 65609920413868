import { LazyLoadImage } from "react-lazy-load-image-component";

function Indicators({ indicators, className }) {
  return (
    <>
      <div
        className={`booking-page-content booking-page-detail-content booking-service-section  ${
          className ? className : ""
        }`}
      >
        <ul>
          {indicators.map((item) => (
            <>
              <li className="d-flex align-items-center gap-2">
                <LazyLoadImage src={item.icon} />{" "}
                <span className="text-center"> {item.text}</span>
              </li>
            </>
          ))}
        </ul>
      </div>
    </>
  );
}
export default Indicators;
